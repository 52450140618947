import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import GeneralInteractionsError1 from '../../../../assets/icons/generalInteractionsError1.svg';
import { PlayLocationEnum, TableItemModel, TrackModel } from '../../../../data';
import { AuthServiceContext, MapperService, PlayerServiceContext, PlaylistServiceContext, TemporaryStatesServiceContext } from '../../../../services';
import { TrackSuggestion } from './components';
import './index.scss';

export type ClosestTracksPropsType = {
  addSuggestionToPlaylist: Function;
  saveInProgress: boolean;
  saveInProgressID: string;
};

export const ClosestTracks = (props: ClosestTracksPropsType) => {
  const authService = useContext(AuthServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  const playerService = useContext(PlayerServiceContext);
  const temporaryStatesService = useContext(TemporaryStatesServiceContext);
  if (!playlistService || !authService || !playerService || !temporaryStatesService) throw new Error('Missing context');

  const [directHover, setDirectHover] = useState(false);
  const [previousFilterX, setPreviousFilterX] = useState(playlistService.currentIsofiPlaylist.filterX.metadataKey);
  const [previousFilterY, setPreviousFilterY] = useState(playlistService.currentIsofiPlaylist.filterY.metadataKey);

  const [columns, setColumns] = useState([
    new TableItemModel('PROXIMITY'),
    new TableItemModel('TRACK'),
    new TableItemModel('ENERGY'),
    new TableItemModel('VALENCE'),
    new TableItemModel('LV. VALENCE'),
    new TableItemModel(MapperService.mapSpotifyMetadataValueToAlias(playlistService.currentIsofiPlaylist.filterX.metadataKey).toLocaleUpperCase()),
    new TableItemModel(MapperService.mapSpotifyMetadataValueToAlias(playlistService.currentIsofiPlaylist.filterY.metadataKey).toLocaleUpperCase()),
    new TableItemModel(''),
    new TableItemModel(''),
  ]);

  const changeCurrentlyHoveredSuggestion = (trackID: string) => {
    temporaryStatesService.updateCurrentlyHoveredSuggestion(trackID);

    if (trackID === '') {
      setDirectHover(false);
    } else {
      setDirectHover(true);
    }
  };

  const playTrack = (track: TrackModel, location: PlayLocationEnum) => {
    playerService.playTrack(track, location);
  };

  const togglePlayback = (newPlaybackValue: boolean) => {
    if (playerService.isPlaying) {
      playerService.spotifyPlayer?.pause();
    } else {
      playerService.spotifyPlayer?.resume();
    }
    playerService.updateIsPlaying(newPlaybackValue);
  };

  useEffect(() => {
    if (
      previousFilterX !== playlistService.currentIsofiPlaylist.filterX.metadataKey ||
      previousFilterY !== playlistService.currentIsofiPlaylist.filterY.metadataKey
    ) {
      setPreviousFilterX(playlistService.currentIsofiPlaylist.filterX.metadataKey);
      setPreviousFilterY(playlistService.currentIsofiPlaylist.filterY.metadataKey);
      const copyColumns = columns.slice();
      copyColumns[5] = new TableItemModel(
        MapperService.mapSpotifyMetadataValueToAlias(playlistService.currentIsofiPlaylist.filterX.metadataKey).toLocaleUpperCase(),
      );
      copyColumns[6] = new TableItemModel(
        MapperService.mapSpotifyMetadataValueToAlias(playlistService.currentIsofiPlaylist.filterY.metadataKey).toLocaleUpperCase(),
      );
      setColumns(copyColumns);
    }
  }, [
    columns,
    previousFilterX,
    previousFilterY,
    playlistService.currentIsofiPlaylist.filterX.metadataKey,
    playlistService.currentIsofiPlaylist.filterY.metadataKey,
  ]);

  return (
    <div className="closest-tracks-container">
      <div className="closest-tracks-title d-flex align-items-center">
        <span className="article-title-lg1">Closest Tracks</span>
        <img src={GeneralInteractionsError1} alt="info icon" />
      </div>
      <div className="closest-tracks-table">
        <Table>
          <thead>
            <tr>
              {columns.map((column, index) => {
                return (
                  <th key={column.name + index} className="column-header section-text-sm6">
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {playlistService.suggestions.length === 0 ? (
              <tr className="empty-results-message">
                <td colSpan={columns.length}>Tracks that are closest to your plot in the graph will appear here.</td>
              </tr>
            ) : null}

            {playlistService.suggestions.map((track, index) => {
              return (
                <TrackSuggestion
                  addSuggestionToPlaylist={props.addSuggestionToPlaylist}
                  accountType={authService.user.product}
                  attributeX={playlistService.currentIsofiPlaylist.filterX.metadataKey}
                  attributeY={playlistService.currentIsofiPlaylist.filterY.metadataKey}
                  checkIfTrackIsAlreadyAdded={playlistService.checkIfTrackIsAlreadyAdded}
                  currentlyHoveredSuggestion={temporaryStatesService.currentlyHoveredSuggestion}
                  currentlyPlayingLocation={playerService.currentlyPlayingLocation}
                  currentlyPlayingTrack={playerService.currentlyPlayingTrack}
                  changeCurrentlyHoveredSuggestion={changeCurrentlyHoveredSuggestion}
                  directHover={directHover}
                  index={index}
                  isPlaying={playerService.isPlaying}
                  key={track.id}
                  playTrack={playTrack}
                  saveInProgress={props.saveInProgress}
                  saveInProgressID={props.saveInProgressID}
                  track={track}
                  togglePlayback={togglePlayback}
                />
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
