import DefaultPlaylistPicture from '../../../assets/images/defaultPlaylistPicture.png';

export class SpotifyImageModel {
  public static getSmallestImage(images: (SpotifyApi.ImageObject | SpotifyImageModel)[]): string {
    let currentSmallestHeight: number | undefined;
    let currentSmallestUrl: string = '';

    if(!images || images.length === 0) return DefaultPlaylistPicture;

    for (const image of images) {
      if (!currentSmallestHeight) {
        currentSmallestHeight = image.height;
        currentSmallestUrl = image.url;
      } else {
        if (image.height && image.height < currentSmallestHeight) {
          currentSmallestHeight = image.height;
          currentSmallestUrl = image.url;
        }
      }
    }
    if (currentSmallestUrl === '') {
      return DefaultPlaylistPicture;
    }

    return currentSmallestUrl;
  }

  constructor(imageObject: SpotifyApi.ImageObject) {
    this.width = imageObject.width ? this.width : 300;
    this.height = imageObject.height ? this.height : 300;
    this.url = imageObject.url;
  }

  public height = 0;
  public width = 0;
  public url = '';
}
