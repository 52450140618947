//import { useCallback, useContext } from 'react';
import { CustomCheckbox } from '../../../../../../components';
import { IsofiPlaylistModel, SpotifyPlaylistModel } from '../../../../../../data';
import './index.scss';
// import { /* AppStatesServiceContext,*/ PlaylistServiceContext } from '../../../../../../services';
// import { useHistory } from 'react-router';

export type SidebarPlaylistPropsType = {
  changeIsofiPlaylistSource: Function;
  changeExpandedPlaylistState: Function;
  checkIfSourceIsSelected: Function;
  currentlyEditingPlaylistID: string;
  isofiPlaylist: IsofiPlaylistModel;
  setActiveIsofiPlaylistAndNavigate: Function;
  spotifyPlaylists: SpotifyPlaylistModel[];
};

export const SidebarPlaylist = (props: SidebarPlaylistPropsType) => {
  // const router = useHistory();
  // const playlistService = useContext(PlaylistServiceContext);
  // const deselect = useCallback(() => {
  //   playlistService?.deselectCurrentlyEditingPlaylist();
  //   router.push('/');
  // },[playlistService]);
  return (
    <div className="playlist-sidebar-item section-text-sm4">
      <div
        className={`playlist-parent-item d-flex justify-content-between ${props.isofiPlaylist.id === props.currentlyEditingPlaylistID ? 'selected' : ''}`}
        onClick={() => props.setActiveIsofiPlaylistAndNavigate(props.isofiPlaylist.id)}>
        <div className="d-flex align-items-center playlist-name">
          <div
            className={`${props.isofiPlaylist.expanded ? 'expanded' : 'collapsed'} dropdown-icon-container`}
            onClick={event => props.changeExpandedPlaylistState(event, props.isofiPlaylist)}></div>
          <span>{props.isofiPlaylist.name}</span>
          <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/playlist/${props.isofiPlaylist.id}`}>
            <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginLeft: '12px' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
          </a>
        </div>
        {/* <div title="Deselect" style={{color:'red', float: 'right', marginTop: 'auto', marginBottom: 'auto', marginRight: '0.25rem'}} onClick={deselect}>X</div> */}
      </div>
      <div className={`playlist-child-item ${props.isofiPlaylist.expanded ? 'expanded' : 'collapsed'}`}>
        {props.spotifyPlaylists.map(spotifyPlaylist => {
          return (
            <label className="single-source d-flex align-items-center" key={spotifyPlaylist.id}>
              <CustomCheckbox
                change={event => props.changeIsofiPlaylistSource(event, props.isofiPlaylist, spotifyPlaylist)}
                checked={props.checkIfSourceIsSelected(props.isofiPlaylist, spotifyPlaylist)}
              />
              <span className="section-text-sm4">{spotifyPlaylist.name}</span>
              <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/playlist/${spotifyPlaylist.id}`}>
                <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginLeft: '4px' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
              </a>
            </label>
          );
        })}
      </div>
    </div>
  );
};
