import { ReactElement } from 'react';
import { AboutModal, ContactModal, ImportPlaylistModal, LegalModal, PrivacyModal, SharePlaylistModal, SetNewPlaylistNameModal, TermsAndConditionsModal } from '../../components';
import { ModalEnums } from '../../data';

class ModalServiceClass {
  /**
   * Checks modal type by string and returns Modal (ReactElement)
   * @param modalType Modal identifier string
   * @returns Returns React element or null
   */
  public checkModal(modalType: string): ReactElement | null {
    switch (modalType) {
      case ModalEnums.ABOUT:
        return <AboutModal />;

      case ModalEnums.CONTACT_US:
        return <ContactModal />;

      case ModalEnums.LEGAL:
        return <LegalModal />;

      case ModalEnums.PRIVACY:
        return <PrivacyModal />;

      case ModalEnums.TCS:
        return <TermsAndConditionsModal />;

      case ModalEnums.NEW_PLAYLIST:
        return <SetNewPlaylistNameModal />;

      case ModalEnums.SHARE_PLAYLIST:
        return <SharePlaylistModal />;

      case ModalEnums.IMPORT_PLAYLIST:
        return <ImportPlaylistModal />;

      default:
        return null;
    }
  }

  /**
   * Checks whether provided url string matches any of the static modals
   * @param url String url to match
   * @returns return boolean from check
   */
  public checkModalURL(url: string): boolean {
    switch (url) {
      case ModalEnums.ABOUT:
      case ModalEnums.CONTACT_US:
      case ModalEnums.LEGAL:
      case ModalEnums.PRIVACY:
      case ModalEnums.TCS:
        return true;

      default:
        return false;
    }
  }
}

export const ModalService = new ModalServiceClass();
