import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { config } from '../../../environments/config';
import { ModalEnums } from '../../../data';
import { AppStatesServiceContext, PlaylistServiceContext } from '../../../services';
import { ImportPlaylistModalContent } from '../ImportPlaylistModalContent';

export const ImportPlaylistModal = () => {
  const appStatesService = useContext(AppStatesServiceContext);
  const playlistService = useContext(PlaylistServiceContext);

  if (!appStatesService || !playlistService) throw new Error('Missing context!');

  const router = useHistory();

  const importPlaylist = async (event: React.FormEvent, id: string): Promise<void> => {
    event.preventDefault();
    playlistService.updateCurrentlyEditingPlaylist(id);
    // playlistService.importPlaylistToIsofiById(id); // NB: import has been mobed into above call if unmatched
    appStatesService.updateModal(ModalEnums.NO_MODAL);
    router.push(`${config.routes.playlistBuilderBase}/${id}`)
  };

  return (
    <ImportPlaylistModalContent
      buttonText="Import playlist"
      submit={importPlaylist}
      labelText="Select a playlist to import"
    />
  );
};
