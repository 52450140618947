import { PlayTrackButton } from '../../../../../../components';
import { AccountTypeEnum, PlayLocationEnum, SpotifyImageModel, TrackModel } from '../../../../../../data';
import './index.scss';

export type SidebarTrackPropsType = {
  accountType: AccountTypeEnum;
  changeCurrentlyHoveredPlaylistTrack: Function;
  currentlyHoveredPlaylistTrack: string;
  currentlyPlayingTrack: TrackModel | undefined;
  currentlyPlayingLocation: PlayLocationEnum;
  directHover: boolean;
  handleOnMouseLeave: Function;
  index: number;
  isPlaying: boolean;
  togglePlayback: Function;
  playTrack: Function;
  track: TrackModel;
};

export const SidebarTrack = (props: SidebarTrackPropsType) => {
  return (
    <div
      className={`playlist-track d-flex justify-content-between ${props.currentlyHoveredPlaylistTrack === props.track.id ? 'active' : ''} `}
      onMouseEnter={() => props.changeCurrentlyHoveredPlaylistTrack(props.track.id)}
      onMouseLeave={() => props.handleOnMouseLeave()}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="track-unique section-text-sm8" style={{ width: '28px' }}>
          <PlayTrackButton
            accountType={props.accountType}
            currentlyPlayingTrack={props.currentlyPlayingTrack}
            currentlyHoveredTrack={props.currentlyHoveredPlaylistTrack}
            currentlyPlayingLocation={props.currentlyPlayingLocation}
            directHover={props.directHover}
            index={props.index}
            location={PlayLocationEnum.PLAYLIST}
            isPlaying={props.isPlaying}
            playTrack={props.playTrack}
            showZero={true}
            track={props.track}
            togglePlayback={props.togglePlayback}
          />{' '}
        </div>
        <img src={SpotifyImageModel.getSmallestImage(props.track.album.images)} alt="Album cover" />
        <div className="track-info d-flex flex-column">
          <span className="artist-name section-text-sm8" title={props.track.artists[0].name}>{props.track.artists[0].name}</span>
          <span className="track-name section-text-sm1" title={props.track.name}>{props.track.name}</span>
          <span className="album-name section-text-sm1" title={props.track.album.name}>{props.track.album.name}</span>
        </div>
        <div className="spotify-link">
          <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/track/${props.track.id}`}>
            <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginLeft: '0px', marginRight: '12px' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
          </a>
        </div>
      </div>
    </div>
  );
};
