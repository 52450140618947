import React, { useContext } from 'react';
import { config } from '../../environments/config';
import { AuthService, AuthServiceContext } from '..';
import { ServiceHOCPropsType, SpotifyBasicInfoModel } from '../../data';

export class SpotifyProviderService {
  constructor(private _authService: AuthService) {}

  public async addTrackToPlaylist(playlistID: string, uris: string[]): Promise<SpotifyApi.AddTracksToPlaylistResponse> {
    const addTrackToPlaylistResponse = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/playlists/${playlistID}/tracks`,
      this._authService.REQUEST_WITH_BODY('POST', { uris }),
    );
    const addTrackToPlaylistObject = await addTrackToPlaylistResponse.json();
    return addTrackToPlaylistObject;
  }

  public async createPlaylist(spotifyBasicInfoModel: SpotifyBasicInfoModel): Promise<SpotifyApi.CreatePlaylistResponse> {
    const createdPlaylistResponse = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/users/${this._authService.user.id}/playlists`,
      this._authService.REQUEST_WITH_BODY('POST', spotifyBasicInfoModel),
    );
    const createdPlaylistObject = await createdPlaylistResponse.json();
    return createdPlaylistObject;
  }

  public async getCurrentlyPlayingTrack(): Promise<any> {
    const currentlyPlayingResponse = await this._authService.requestWrapper(
      'https://api.spotify.com/v1/me/player/currently-playing',
      this._authService.GET_REQUEST,
    );
    const currentlyPlayingObject = await currentlyPlayingResponse.json();
    return currentlyPlayingObject;
  }

  // public async getMultipleTracksMetadata(ids: string[]): Promise<SpotifyApi.AudioFeaturesResponse> {
  public async getMultipleTracksMetadata(ids: string[]): Promise<SpotifyApi.MultipleAudioFeaturesResponse> {
    const trackMetadataResponse = await this._authService.requestWrapper(this._getTracksMetadataUri(ids), this._authService.GET_REQUEST);
    const trackMetadataObject = await trackMetadataResponse.json();
    return trackMetadataObject;
  }

  public async getUserPlaylistsWithPaging() : Promise<SpotifyApi.PlaylistObjectSimplified[]> {
    var response = await this.getUserPlaylists();
    var playlists = response.items;
    while(response.next) {
      response = await this.getUserPlaylists(response.next);
      playlists = playlists.concat(response.items);
    }
    return playlists;
  }

  public async getUserPlaylists(nextUrl?: string): Promise<SpotifyApi.ListOfUsersPlaylistsResponse> {
    const playlistResponse = await this._authService.requestWrapper(nextUrl ?? config.spotify.userPlaylistsUri, this._authService.GET_REQUEST);
    const playlistObject = await playlistResponse.json();
    return playlistObject;
  }

  public async getPlaylist(id: string): Promise<SpotifyApi.PlaylistBaseObject> {
    const playlistResponse = await this._authService.requestWrapper(this._getPlaylistByIDUri(id), this._authService.GET_REQUEST);
    const playlistObject = await playlistResponse.json();
    return playlistObject;
  }

  public async getTracksByPlaylistIDWithPaging(id: string): Promise<SpotifyApi.PlaylistTrackObject[]> {
    var tracksResponse = await this.getTracksByPlaylistID(id);
    var tracks = tracksResponse.items;
    while(tracksResponse.next) {
      tracksResponse = await this.getNextTracks(tracksResponse.next);
      tracks = tracks.concat(tracksResponse.items);
    }
    return tracks;
  }

  public async getTracksByPlaylistID(id: string): Promise<SpotifyApi.PlaylistTrackResponse> {
    const tracksResponse = await this._authService.requestWrapper(this._getTracksByPlaylistIDUri(id), this._authService.GET_REQUEST);
    const tracksObject = await tracksResponse.json();
    return tracksObject;
  }

  public async getNextTracks(nextUrl: string): Promise<SpotifyApi.PlaylistTrackResponse> {
    const tracksResponse = await this._authService.requestWrapper(nextUrl, this._authService.GET_REQUEST);
    const tracksObject = await tracksResponse.json();
    return tracksObject;
  }

  public async playTrack(uris: string[], deviceID: string, position_ms: number): Promise<SpotifyApi.PlaybackObject> {
    const playTrackResponse = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/me/player/play?device_id=${deviceID}`,
      this._authService.REQUEST_WITH_BODY('PUT', { uris, position_ms }),
    );

    return playTrackResponse;
  }

  public async resetPlaylist(playlistID: string): Promise<SpotifyApi.ReorderPlaylistTracksResponse> {
    const resetPlaylistResponse = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/playlists/${playlistID}/tracks`,
      this._authService.REQUEST_WITH_BODY('PUT', { uris: [] }),
    );
    const resetPlaylistObject = await resetPlaylistResponse.json();
    return resetPlaylistObject;
  }

  public async seekTrack(deviceID: string, position_ms: number) {
    const playTrackResponse = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/me/player/seek?position_ms=${position_ms}&device_id=${deviceID}`,
      this._authService.REQUEST_WITH_BODY('PUT'),
    );
    return playTrackResponse;
  }

  public async transferPlaybackToIsofiPlayer(body: any): Promise<any> {
    const response = await this._authService.requestWrapper(
      `https://api.spotify.com/v1/me/player`,
      this._authService.REQUEST_WITH_BODY('PUT', body));
    return response;
  }

  // Private helper functions

  private _getTracksByPlaylistIDUri(id: string): string {
    return `https://api.spotify.com/v1/playlists/${id}/tracks`;
  }

  private _getPlaylistByIDUri(id: string): string {
    return `https://api.spotify.com/v1/playlists/${id}`;
  }

  private _getTracksMetadataUri(ids: string[]): string {
    return `https://api.spotify.com/v1/audio-features?ids=${ids.join(',')}`;
  }
}

export const SpotifyProviderServiceContext = React.createContext<SpotifyProviderService | undefined>(undefined);

export const SpotifyProviderServiceHOC = (props: ServiceHOCPropsType) => {
  const authService = useContext(AuthServiceContext);
  if (!authService) throw new Error('Missing context');

  const service = new SpotifyProviderService(authService);

  return <SpotifyProviderServiceContext.Provider value={service}>{props.children}</SpotifyProviderServiceContext.Provider>;
};
