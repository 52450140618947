export const ModalEnums = {
  NO_MODAL: '/',
  ABOUT: '/about',
  CONTACT_US: '/contact',
  LEGAL: '/legal',
  PRIVACY: '/privacy',
  TCS: '/terms-and-conditions',
  NEW_PLAYLIST: 'isofi-new-name',
  SHARE_PLAYLIST: 'share-playlist',
  IMPORT_PLAYLIST: 'import-playlist',
};

Object.freeze(ModalEnums);
