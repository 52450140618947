import { useCallback, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from '../../../hooks/useNavigate';
import { config } from '../../../environments/config';
import { ADAuthContext } from '../../../services';
// import './index.scss';

//export type AuthLoginAADPropsType = {};
export const AuthLoginAAD = (/*props: AuthLoginAADPropsType*/) => {
    const adAuth = useContext(ADAuthContext);
    if(!adAuth) throw new Error('Missing ADAuth context');
    const { updateUser } = adAuth;
    const navigate = useNavigate();
    const login = useCallback(user => {
        // alert(`Logging in as ${JSON.stringify(user)}`);
        // updateUser(user);
        //navigate(config.aad.redirectUri);
        //navigate(`${config.aad.redirectUri}#user=${encodeURIComponent(JSON.stringify(user))}`);
        navigate(`/callback#user=${encodeURIComponent(JSON.stringify(user))}`);
    }, [updateUser,navigate]);
    if(!adAuth) throw new Error('Missing ADAuth context');
    return <div>
        <Button variant="success" onClick={() => login({
            userDetails: "new-user@example.com",
            userRoles: ["authenticated"],
            identityProvider: "aad",
            userId: "0123456789",
            claims: [],
        })}>Login (New)</Button>
        <Button variant="success" onClick={() => login({
            userDetails: "therapist-joe@example.nhs.uk",
            userRoles: ["authenticated","therapist"],
            identityProvider: "aad",
            userId: "0123456789",
            claims: [],
        })}>Login (Therapist)</Button>
        <Button variant="success" onClick={() => login({
            userDetails: "admin@isofi.app",
            userRoles: ["authenticated", "administrator"],
            identityProvider: "aad",
            userId: "0123456789",
            claims: [],
        })}>Login (Admin)</Button>
    </div>;
}