import React, { useContext } from 'react';
import { MapperService, PlaylistService, PlaylistServiceContext, SpotifyProviderService, SpotifyProviderServiceContext, useStateForServices } from '..';
import { PlayLocationEnum, ServiceHOCPropsType, StateForService, TrackModel } from '../../data';

let currentProgressRef: NodeJS.Timeout | undefined;

export class PlayerService {
  constructor(
    private _playlistService: PlaylistService,
    private _spotifyProviderService: SpotifyProviderService,
    private _deviceID: StateForService<string>,
    private _currentlyPlayingTrack: StateForService<TrackModel | undefined>,
    private _currentlyPlayingLocation: StateForService<PlayLocationEnum>,
    private _currentProgress: StateForService<number>,
    private _isPlaying: StateForService<boolean>,
    private _spotifyPlayer: StateForService<Spotify.Player | undefined>,
    private _volume: StateForService<number>,
    private _progressTimerRef: StateForService<NodeJS.Timeout | undefined>,
  ) {}

  get currentlyPlayingTrack(): TrackModel | undefined {
    return this._currentlyPlayingTrack.value;
  }

  get currentlyPlayingLocation(): PlayLocationEnum {
    return this._currentlyPlayingLocation.value;
  }

  get currentProgress(): number {
    return this._currentProgress.value;
  }

  get deviceID(): string {
    return this._deviceID.value;
  }

  get isPlaying(): boolean {
    return this._isPlaying.value;
  }

  get spotifyPlayer(): Spotify.Player | undefined {
    return this._spotifyPlayer.value;
  }

  get volume(): number {
    return this._volume.value;
  }

  /**
   * Change Spotify player volume
   * @param value Expects a number value between 0 and 1
   */
  public changeVolume(value: number): void {
    this._volume.update(value);
    this._updateVolumeOnSpotify(value);
  }

  /**
   * Checks whether provided track ID exists in currently editing isofi playlist
   * @param track TrackModel to check
   * @returns Boolean of the check
   */
  public checkCurrentlyPlayingTrackLocation = (trackID: string): boolean => {
    for (const isofiTrack of this._playlistService.currentIsofiPlaylist.tracks) {
      if (isofiTrack.id === trackID) {
        return true;
      }
    }

    return false;
  };

  /**
   * Gets display progress in a presentable display format
   * @returns Presentable string
   */
  public getProgressInDisplayFormat(value: number): string {
    if (value > 0) {
      let duration = '';
      const minutes = Math.floor(value / 1000 / 60);
      const seconds = Math.floor((value / 1000) % 60);
      duration += minutes < 10 ? `0${minutes}` : minutes;
      duration += ':';
      duration += seconds < 10 ? `0${seconds}` : seconds;

      return duration;
    } else return '00:00';
  }

  /**
   * Plays the next track based on currently playing location
   */
  public initializeNextTrackManually = (): void => {
    if (this.currentlyPlayingLocation === PlayLocationEnum.SUGGESTIONS) {
      this.playNextTrack();
    } else if (this.currentlyPlayingLocation === PlayLocationEnum.PLAYLIST) {
      if (this._playlistService.currentIsofiPlaylist.tracks.length > 1) {
        this.playNextTrack();
      } else {
        if (currentProgressRef !== undefined) {
          clearInterval(currentProgressRef);
        }
        this.updateCurrentProgress(0);
        this.updateIsPlaying(false, 0);
      }
    }
  };

  /**
   * Main function to play tracks from isofi
   * @param track Track to play
   * @param location Location of track's origin
   * @param seek Boolean whether track should be played or just seeked
   * @param position_ms Position to start track playback
   */
  public playTrack = async (track: TrackModel, location: PlayLocationEnum, seek: boolean = false, position_ms: number = 0) => {
    if (location !== PlayLocationEnum.NATIVE_APP) {
      const uris = MapperService.mapTracksToSpotifyPlayerString([track]);
      if (currentProgressRef !== undefined) {
        clearTimeout(currentProgressRef);
      }

      let playTrackResponse;

      if (seek) {
        playTrackResponse = await this._spotifyProviderService.seekTrack(this.deviceID, position_ms);
      } else {
        playTrackResponse = await this._spotifyProviderService.playTrack(uris, this.deviceID, position_ms);
      }

      if (playTrackResponse.ok) {
        if (this.currentlyPlayingLocation !== location) {
          this.updateCurrentlyPlayingLocation(location);
        }
      }
    }
  };

  /**
   * Plays next track in playlist if there is any
   */
  public playNextTrack = (): void => {
    if (this.currentlyPlayingLocation === PlayLocationEnum.NATIVE_APP) {
      if (this.spotifyPlayer) {
        this.spotifyPlayer.getCurrentState().then((state: Spotify.PlaybackState | null) => {
          if (state) {
            if (state.track_window.next_tracks.length !== 0) {
              this.updateCurrentlyPlayingLocation(PlayLocationEnum.NATIVE_APP);
              this.spotifyPlayer?.nextTrack();
            } else {
              this.spotifyPlayer?.pause();
              this.updateIsPlaying(false, 0);
            }
          }
        });
      }
    } else if (this.currentlyPlayingLocation === PlayLocationEnum.PLAYLIST) {
      let index = 0;
      let nextTrack: TrackModel | undefined;
      if (this.currentlyPlayingTrack) {
        for (const track of this._playlistService.currentIsofiPlaylist.tracks) {
          if (track.id === this.currentlyPlayingTrack.id) {
            if (index !== this._playlistService.currentIsofiPlaylist.tracks.length - 1) {
              nextTrack = this._playlistService.currentIsofiPlaylist.tracks[index + 1];
              break;
            }
          }
          index++;
        }

        if (nextTrack) {
          this.playTrack(nextTrack, this.currentlyPlayingLocation);
        } else {
          this.playTrack(this._playlistService.currentIsofiPlaylist.tracks[0], this.currentlyPlayingLocation);
        }
      }
    } else if (this.currentlyPlayingLocation === PlayLocationEnum.SUGGESTIONS) {
      let index = 0;
      let nextTrack: TrackModel | undefined;
      if (this.currentlyPlayingTrack) {
        for (const track of this._playlistService.suggestions) {
          if (track.id === this.currentlyPlayingTrack.id) {
            if (index !== this._playlistService.suggestions.length - 1) {
              nextTrack = this._playlistService.suggestions[index + 1];
              break;
            }
          }
          index++;
        }

        if (nextTrack) {
          this.playTrack(nextTrack, this.currentlyPlayingLocation);
        } else {
          this.playTrack(this._playlistService.suggestions[0], this.currentlyPlayingLocation);
        }
      }
    }
  };

  /**
   * Plays previous track in playlist if there is any
   */
  public playPreviousTrack = (): void => {
    if (this.currentlyPlayingLocation === PlayLocationEnum.NATIVE_APP) {
      if (this.spotifyPlayer) {
        this.spotifyPlayer.getCurrentState().then((state: Spotify.PlaybackState | null) => {
          if (state) {
            if (state.track_window.previous_tracks.length !== 0) {
              this.updateCurrentlyPlayingLocation(PlayLocationEnum.NATIVE_APP);
              this.spotifyPlayer?.previousTrack();
            } else {
              this.spotifyPlayer?.pause();
              this.updateIsPlaying(false, 0);
            }
          }
        });
      }
    } else if (this.currentlyPlayingLocation === PlayLocationEnum.PLAYLIST) {
      let index = 0;
      let previousTrack: TrackModel | undefined;
      if (this.currentlyPlayingTrack) {
        for (const track of this._playlistService.currentIsofiPlaylist.tracks) {
          if (track.id === this.currentlyPlayingTrack.id) {
            if (index !== 0) {
              previousTrack = this._playlistService.currentIsofiPlaylist.tracks[index - 1];
              break;
            }
          }
          index++;
        }

        if (previousTrack) {
          this.playTrack(previousTrack, this.currentlyPlayingLocation);
        } else {
          this.playTrack(this._playlistService.currentIsofiPlaylist.tracks[0], this.currentlyPlayingLocation);
        }
      }
    } else if (this.currentlyPlayingLocation === PlayLocationEnum.SUGGESTIONS) {
      let index = 0;
      let previousTrack: TrackModel | undefined;
      if (this.currentlyPlayingTrack) {
        for (const track of this._playlistService.suggestions) {
          if (track.id === this.currentlyPlayingTrack.id) {
            if (index !== 0) {
              previousTrack = this._playlistService.suggestions[index - 1];
              break;
            }
          }
          index++;
        }

        if (previousTrack) {
          this.playTrack(previousTrack, this.currentlyPlayingLocation);
        } else {
          this.playTrack(this._playlistService.suggestions[0], this.currentlyPlayingLocation);
        }
      }
    }
  };

  public skipWithDebounce = (newProgressPoint: number): void => {
    if (this.spotifyPlayer) {
      this._progressDebounce(this.skipToPoint.bind(this, newProgressPoint), 50);
    }
  };

  /**
   * Changes playback progress
   * @param newProgressPoint New track playback position
   */
  public skipToPoint = (newProgressPoint: number): void => {
    if (this.currentlyPlayingLocation !== PlayLocationEnum.NATIVE_APP) {
      if (this.currentlyPlayingTrack) {
        if (newProgressPoint < 0) {
          this.playTrack(this.currentlyPlayingTrack, this.currentlyPlayingLocation);
        } else if (newProgressPoint > this.currentlyPlayingTrack?.duration_ms) {
          this.playNextTrack();
        } else {
          this.playTrack(this.currentlyPlayingTrack, this.currentlyPlayingLocation, true, newProgressPoint);
        }
        this.updateIsPlaying(true, newProgressPoint);
      }
    } else {
      this.updateIsPlaying(true, newProgressPoint);
      this.spotifyPlayer?.seek(newProgressPoint);
    }
  };

  /**
   * Updates playing state and time
   * @param value Value of play state (PLAY - true, PAUSE - false)
   * @param currentProgress Latest progress time
   */
  public updateIsPlaying(value: boolean, currentProgress: number = this._currentProgress.value): void {
    if (this.spotifyPlayer) {
      if (value) {
        if (currentProgressRef !== undefined) {
          this.updateCurrentProgress(currentProgress);
          clearInterval(currentProgressRef);
          this._updateSecondInterval(currentProgress);
        } else {
          this._updateSecondInterval(currentProgress);
        }
      } else {
        if (currentProgressRef !== undefined) {
          clearInterval(currentProgressRef);
          currentProgressRef = undefined;
        }

        if (currentProgress !== this._currentProgress.value) {
          this.updateCurrentProgress(currentProgress);
        }
      }
      this._isPlaying.update(value);
    }
  }

  public updateCurrentlyPlayingTrack(value: TrackModel): void {
    this._currentlyPlayingTrack.update(value);
  }

  public updateCurrentlyPlayingLocation(value: PlayLocationEnum): void {
    this._currentlyPlayingLocation.update(value);
  }

  public updateCurrentProgress(value: number): void {
    this._currentProgress.update(value);
  }

  public updateDeviceID(value: string): void {
    this._deviceID.update(value);
  }

  public updateSpotifyPlayer(value: Spotify.Player | undefined): void {
    this._spotifyPlayer.update(value);
  }

  // Private helper functions

  /**
   * Updates progress ref and sets new interval with latest progress value
   * @param progress New progress value
   */
  private _updateSecondInterval(progress: number) {
    currentProgressRef = setInterval(() => {
      progress += 1000;
      this.updateCurrentProgress(progress);
    }, 1000);
  }

  private async _updateVolumeOnSpotify(value: number): Promise<any> {
    this.spotifyPlayer?.setVolume(value);
  }

  /**
   * Debouncer (useful in case Volume input switches to range)
   * @param func Function to call after timeout time passes
   * @param timeout Length of time to wait before calling function
   */
  private _progressDebounce(func: Function, timeout: number = 500) {
    if (this._progressTimerRef.value) {
      clearTimeout(this._progressTimerRef.value);
    }

    this._progressTimerRef.update(
      setTimeout(() => {
        func();
      }, timeout),
    );
  }
}

export const PlayerServiceContext = React.createContext<PlayerService | undefined>(undefined);

export const PlayerServiceHOC = (props: ServiceHOCPropsType) => {
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  if (!spotifyProviderService || !playlistService) throw new Error('Missing context');

  const service = new PlayerService(
    playlistService,
    spotifyProviderService,
    useStateForServices<string>(''),
    useStateForServices<TrackModel | undefined>(undefined),
    useStateForServices<PlayLocationEnum>(PlayLocationEnum.PLAYLIST),
    useStateForServices<number>(0),
    useStateForServices<boolean>(false),
    useStateForServices<Spotify.Player | undefined>(undefined),
    useStateForServices<number>(0.5),
    useStateForServices<NodeJS.Timeout | undefined>(undefined),
  );

  return <PlayerServiceContext.Provider value={service}>{props.children}</PlayerServiceContext.Provider>;
};
