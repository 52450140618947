// import React, { useContext } from 'react';
// import { ServiceHOCPropsType, StateForService } from "../../data";
// import { ADUserModel } from "../../data/Models/ADUser";
// import { config } from '../../environments/config';
// import { /*AppStatesService,*/ AppStatesServiceContext, getPopUpParams, useStateForServices } from '..';
// // import { AppStatesEnum, ServiceHOCPropsType, StateForService, TokenModel, TokenObjectType, UserModel } from '../../data';

// export class ADAuth {
//     constructor(
//         // private _appStatesService: AppStatesService,
//         private _authenticated: StateForService<boolean>,
//         private _user: StateForService<ADUserModel|undefined>,
//     ) {
//       this.authorize.bind(this);
//       this._getAuthorizeUri.bind(this);
//       this.hasRole.bind(this);
//   }

//   public get authenticated(): boolean {
//     return this._authenticated.value;
//   }

//   public updateAuthenticated(value: boolean): void {
//     this._authenticated.update(value);
//   }

//   public get user(): ADUserModel|undefined {
//     return this._user?.value;
//   }

//   public updateUser(value?: ADUserModel): void {
//     this._user.update(value);
//   }

//   public get roles() : string[] {
//     console.debug(`ADAuth get roles: this._user=${this._user ? JSON.stringify(this._user) : typeof this._user}`);
//     return this._user.value?.userRoles ?? [];
//   }

//   public hasRole(roleName:string):boolean {
//     console.debug(`ADAuth has role (roleName=${roleName}): this._user=${this._user ? JSON.stringify(this._user) : typeof this._user}, userRoles=${this._user.value?.userRoles ? JSON.stringify(this._user.value?.userRoles) : typeof this._user.value?.userRoles}`);
//     //console.debug(`ADAuth has role (roleName=${roleName}): this._user=${this._user ? JSON.stringify(this._user) : typeof this._user}, this.roles=${this.roles ? JSON.stringify(this.roles) : typeof this.roles}`);
//     return this.roles?.includes(roleName);
//   }

//   public authorize(): Promise<ADUserModel | undefined> {
//     return new Promise((resolve/*, reject*/) => {
//     const popUp: Window = window.open(this._getAuthorizeUri(), 'Therapist Login', getPopUpParams())!;

//     const timerRef = setInterval(
//         async () => {
//             try {
//                 if ((popUp.document && popUp.document.URL) || (popUp.window && popUp.window.document.URL)) {
//                     const returnUrl = popUp.document ? popUp.document.URL : popUp.window.document.URL;
//                     // if (returnUrl.indexOf(config.aad.redirectUri) !== -1) {
//                     if (returnUrl.indexOf(config.aad.redirectUri) === 0) { // e.g. must match start of URL, not a parameter, etc.
//                       console.log(`Detected callback: `, {
//                         returnUrl,
//                         redirectUri: config.aad.redirectUri,
//                         indexOf: returnUrl.indexOf(config.aad.redirectUri),
//                       });
//                       // const tokenObject = this._extractTokenFromHash(returnUrl, config.spotify.redirectUri);
//                       // const token = new TokenModel(tokenObject);
//                       // const amIAuthenticated = await this.whoAmI(token);
//                       clearInterval(timerRef);
//                       if(returnUrl.indexOf('#') > -1) {
//                         // alert(`Detected callback: ${returnUrl}`);
//                         const paramsString = returnUrl.split('#')[1];
//                         const params = new URLSearchParams(paramsString);
//                         if(params.get("user")?.length) {
//                           const user = JSON.parse(params.get("user")||"{}") as ADUserModel;
//                           this.updateUser(user);
//                           this.updateAuthenticated(true);
//                           resolve(user);
//                           popUp.close();
//                           return;
//                         }
//                       }
//                       popUp.close();

//                       let user = (await (await fetch(config.aad.whoAmURI)).json()).clientPrincipal;
//                       console.debug('AAD login, user=', user);
//                       this.updateUser(user);
//                       this.updateAuthenticated(user?.clientPrincipal?.userRoles.includes("authenticated"));
//                       resolve(user);
//                     }
//                 }
//             } catch (_) {}
//         }, 500);
//     });
//   }

//   /**
//    * Log out user and that call authorize pop up screen which has Change user option( which is not intuitive but best case for now )
//    */
//     // public async loginWithAnotherAccount(): Promise<any> {
//     //  this.logout();
//     //  this.authorize(true);
//     // }

//   /**
//    * Logs user out. Reset auth and playlist data
//    */
//   public logout(): void {
//     //this.updateHeaders(new Headers());
//     //this.updateToken(TokenModel.createEmptyToken());
//     this.updateUser(undefined);
//     this.updateAuthenticated(false);
//     fetch(config.aad.logoutUri);
//   }

//   /**
//    * Wrapper function that should only execute if user is authenticated. If not, call Spotify login pop up
//    * @param callback Function to execute if user is authenticated
//    */
//   public onlyAuthenticated(callback: Function): void {
//     if (this.authenticated) {
//       callback();
//     } else {
//       this.authorize().then(res => {
//         callback();
//       });
//     }
//   }

//   /* Private functions */
//   /**
//    * Helper that puts together authorization uri
//    * @returns string authorization uri
//    */
//   private _getAuthorizeUri(): string {
//     return `${config.aad.authorizeURL}?post_login_redirect_uri=${config.aad.redirectUri}`;
//   }
// }

// export const ADAuthContext = React.createContext<ADAuth | undefined>(undefined);

// export const ADAuthHOC = (props: ServiceHOCPropsType) => {
//   // Get contexts
//   const appStatesService = useContext(AppStatesServiceContext);
//   if (!appStatesService) throw new Error('Missing context!');

//   const service = new ADAuth(
//     // appStatesService,
//     useStateForServices<boolean>(false),
//     useStateForServices<ADUserModel|undefined>(undefined),
//   );

//   return <ADAuthContext.Provider value={service}>{props.children}</ADAuthContext.Provider>;
// };

import { ReactNode, createContext } from 'react';

class ADAuth {
  authenticated: boolean;
  user: any;
  authorize: Function;
  logout: Function;

  constructor() {
    this.authenticated = true;
    this.user = {};
    this.authorize = () => {};
    this.logout = () => {};  
  }
}

export const ADAuthContext = createContext<ADAuth>(new ADAuth());

interface Props {
  children?: ReactNode;
}

export function ADAuthHOC({children}: Props):JSX.Element {
  return <>{children}</>;
}
