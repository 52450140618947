import React, { useContext } from 'react';
import { config } from '../../environments/config';
import {
  AppStatesService,
  AppStatesServiceContext,
  AuthService,
  AuthServiceContext,
  createChunks,
  MapperService,
  SpotifyProviderService,
  SpotifyProviderServiceContext,
  useStateForServices,
} from '../index';
import {
  BackgroundPlaylistFetchModel,
  IsofiPlaylistObjectType,
  LocalStorageEnum,
  SpotifyPlaylistModel,
  PlaylistObjectType,
  ServiceHOCPropsType,
  StateForService,
  TrackMetadataModel,
  TrackModel,
  FilterTypeEnum,
  AxisFilterModel,
  IsofiPlaylistModel,
  FilterPresetModel,
  BasicFilterPresetEnum,
  PlaylistStatsModel,
  SpotifyBasicInfoModel,
  PlaylistEnums,
  BackgroundFetchStateEnum,
} from '../../data';
import ConfusionPeace from '../../assets/images/confusionPeace.png';
import DistractedFocused from '../../assets/images/distractedFocused.png';
import HopelessHopeful from '../../assets/images/hopelessHopeful.png';
export class PlaylistService {
  constructor(
    private _appStatesService: AppStatesService,
    private _authService: AuthService,
    private _spotifyProviderService: SpotifyProviderService,
    private _isofiSources: StateForService<SpotifyPlaylistModel[]>,
    private _isofiPlaylists: StateForService<IsofiPlaylistModel[]>,
    private _suggestions: StateForService<TrackModel[]>,
    private readonly _currentIsofiPlaylist: StateForService<IsofiPlaylistModel>,
  ) {}

  public trackMetadataValues: (keyof TrackMetadataModel)[] = [
    'energy',
    'valence',
    'acousticness',
    'danceability',
    'instrumentalness',
    'liveness',
    'loudness',
    'key',
    'speechiness',
    'tempo',
  ];

  public listOfBasicFilters: FilterPresetModel[] = [
    new FilterPresetModel('Distracted > Focused', DistractedFocused, BasicFilterPresetEnum.DISTRACTED_FOCUSED),
    new FilterPresetModel('Confusion > Peace', ConfusionPeace, BasicFilterPresetEnum.CONFUSION_PEACE),
    new FilterPresetModel('Feeling hopeless > Hopeful', HopelessHopeful, BasicFilterPresetEnum.HOPELESS_HOPEFUL),
  ];

  /* Public methods for getting & setting service state*/
  public get isofiSources(): SpotifyPlaylistModel[] {
    return this._isofiSources.value;
  }

  public get isofiPlaylists(): IsofiPlaylistModel[] {
    return this._isofiPlaylists.value;
  }

  public get currentIsofiPlaylist(): IsofiPlaylistModel {
    return this._currentIsofiPlaylist.value;
  }

  public get suggestions(): TrackModel[] {
    return this._suggestions.value;
  }

  /* PUBLIC METHODS THAT FOR DATA MANIPULATIONS AND SIDE EFFECTS */

  /**
   * Adds playlist source to isofi playlist
   * @param id ID of the spotify playlist to add to sources
   */
  public async addPlaylistSource(playlist: SpotifyPlaylistModel): Promise<void> {
    let duplicateCheck = false;
    /* Quick check for duplicates to be safe */
    for (const isofiSource of this.isofiSources) {
      if (isofiSource.id === playlist.id) {
        duplicateCheck = true;
        break;
      }
    }

    if (!duplicateCheck) {
      this._appStatesService.updateBackgroundFetch(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.INITIATED, [playlist.id]));
    }
  }

  /**
   * Adds track to currently editing isofi playlist
   * @param track Track model to add to playlist
   */
  public async addTrackToPlaylist(trackToAdd: TrackModel): Promise<TrackModel | undefined> {
    const uris = [...MapperService.mapTracksToSpotifyPlayerString([trackToAdd])];
    const addTrackResponse = await this._spotifyProviderService.addTrackToPlaylist(this.currentIsofiPlaylist.id, uris);
    if (addTrackResponse.snapshot_id) {
      this._changeIsofiPlaylists((isofiPlaylist: IsofiPlaylistModel) => {
        isofiPlaylist.tracks.push(trackToAdd);
      });
      return trackToAdd;
    }
  }

  /**
   * Adds spotify source to isofi playlist
   * @param isofiPlaylistID ID of the isofi playlist to add source to
   * @param spotifyPlaylistID ID of the spotify playlist to be added to isofi playlist sources
   */
  public addSourceToIsofiPlaylist(isofiPlaylist: IsofiPlaylistModel, spotifyPlaylist: SpotifyPlaylistModel): void {
    this._changeIsofiPlaylists(
      (isofiPlaylistToChange: IsofiPlaylistModel) => {
        isofiPlaylistToChange.sources.push(spotifyPlaylist.id);
      },
      [isofiPlaylist.id],
    );
  }

  /**
   * Calculates min and max values for track metadata values
   * @param keyX Track metadata value
   * @param keyY Track metadata vaulue
   * @returns Object with 4 min/max values (2 for x, 2 for y)
   */
  public calculateMinMax(keyX: keyof TrackMetadataModel, keyY: keyof TrackMetadataModel): { [key: string]: number } {
    let minX, maxX, minY, maxY;

    for (const playlist of this.isofiPlaylists) {
      if (playlist.id === this.currentIsofiPlaylist.id) {
        for (const source of playlist.sources) {
          for (const spotifyPlaylist of this.isofiSources) {
            if (spotifyPlaylist.id === source) {
              for (const track of spotifyPlaylist.tracks) {
                if (track.metadata[keyX] === 0 || track.metadata[keyY] === 0) {
                  continue;
                }

                if (!minX || track.metadata[keyX] < minX) {
                  minX = track.metadata[keyX];
                }

                if (!maxX || track.metadata[keyX] > maxX) {
                  maxX = track.metadata[keyX];
                }

                if (!minY || track.metadata[keyY] < minY) {
                  minY = track.metadata[keyY];
                }

                if (!maxY || track.metadata[keyY] > maxY) {
                  maxY = track.metadata[keyY];
                }
              }
            }
          }
        }
        break;
      }
    }

    return {
      minX: 0,
      maxX: maxX ? maxX + 0.1 : 1,
      minY: 0,
      maxY: maxY ? maxY + 0.1 : 1,
    };
  }

  /**
   * Expands isofi playlist in left sidebar menu
   * @param isofiPlaylistID ID of the isofi playlist to expand
   */
  public changeExpandedPlaylistState(isofiPlaylist: IsofiPlaylistModel): void {
    this._changeIsofiPlaylists(
      (isofiPlaylistToChange: IsofiPlaylistModel) => {
        isofiPlaylistToChange.expanded = !isofiPlaylistToChange.expanded;
      },
      [isofiPlaylist.id],
    );
  }

  /**
   * Checks if spotify playlist is already selected for given isofi playlist
   * @param isofiPlaylistID Isofi playlist ID to check
   * @param spotifyPlaylistID Spotify playlist ID to check
   * @returns boolean from the check
   */
  public checkIfSourceIsSelected(isofiPlaylists: IsofiPlaylistModel[], isofiPlaylist: IsofiPlaylistModel, spotifyPlaylist: SpotifyPlaylistModel): boolean {
    for (const currentIsofiPlaylist of isofiPlaylists) {
      if (currentIsofiPlaylist.id === isofiPlaylist.id) {
        return currentIsofiPlaylist.sources.includes(spotifyPlaylist.id);
      }
    }

    return false;
  }

  /**
   * Checks if suggested track is already added to isofi playlist
   * @param isofiTrackID Isofi track ID to check
   * @returns Boolean from the check
   */
  public checkIfTrackIsAlreadyAdded = (isofiTrack: TrackModel): boolean => {
    if (this.currentIsofiPlaylist) {
      for (const track of this.currentIsofiPlaylist?.tracks) {
        if (track.id === isofiTrack.id) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Creates new isofi playlist and sets it as currently editing
   * @param name How to name an isofi playlist
   * @returns number ID of the created isofi playlist
   */
  public async createNewIsofiPlaylist(name: string): Promise<IsofiPlaylistModel> {
    const copyIsofiPlaylists = this.isofiPlaylists.slice();
    const { x: keyX, y: keyY } = MapperService.mapAxisValuesFromPreset(this.listOfBasicFilters[0].presetValue);
    const createdPlaylistResponse = await this._spotifyProviderService.createPlaylist(
      new SpotifyBasicInfoModel(name, config.spotify.playlistDescriptionIdentifier),
    );

    const newIsofiPlaylist = new IsofiPlaylistModel(
      createdPlaylistResponse.id,
      createdPlaylistResponse.name,
      this._authService.user.id,
      FilterTypeEnum.BASIC,
      new AxisFilterModel(keyX, 0, 1),
      new AxisFilterModel(keyY, 0, 1),
      this.listOfBasicFilters[0].presetValue,
    );
    copyIsofiPlaylists.push(newIsofiPlaylist);
    this.saveAndUpdateCurrentIsofiPlaylist(newIsofiPlaylist);
    this.updateIsofiPlaylists(copyIsofiPlaylists);

    return newIsofiPlaylist;
  }

  /**
   * Gets and sets suggestions based on x & y coordinates
   * @param valueX Value of X coordinate (already converted from pixel to graph value)
   * @param valueY Value of Y coordinate  (already converted from pixel to graph value)
   * @returns Array of tracks
   */
  public getAndSetSuggestions(valueX: number, valueY: number): TrackModel[] {
    const finalSuggestions = this._getNearbySongs(this.currentIsofiPlaylist.filterX.metadataKey, this.currentIsofiPlaylist.filterY.metadataKey, valueX, valueY);
    this._suggestions.update(finalSuggestions);

    return finalSuggestions;
  }

  /**
   * Gets all user playlists from spotify
   * @returns Promise with boolean if response is successful
   */
  public async getUserPlaylists(): Promise<boolean> {
    var spotifyPlaylists = await this._spotifyProviderService.getUserPlaylistsWithPaging();
    const playlists = spotifyPlaylists.map((playlist: SpotifyApi.PlaylistBaseObject) => new SpotifyPlaylistModel(playlist));
    this.updateIsofiSources(playlists);

    return true;
  }

  /**
   * Fetches track data & metadata in background
   * @param playlistIDs IDs of spotify playlists to fetch data. If no ID is provided, then fetch for all user playlists is initiated
   * @returns Void Promise
   */
  public async fetchAndUpdateDataInBackground(playlistIDs: string[]): Promise<void> {
    // Set all currently fetching playlists state to loading
    this._changeIsofiSources(
      (spotifyPlaylist: SpotifyPlaylistModel) => {
        if (playlistIDs.includes(spotifyPlaylist.id)) {
          spotifyPlaylist.loading = true;
        }
      },
      this.isofiSources.map(p => p.id),
    );
    // Get current user playlists and filter those cached that no longer exist on Spotify
    const userPlaylistsResponse = await this._spotifyProviderService.getUserPlaylistsWithPaging();
    const playlistsToUpdate = MapperService.mapCachedPlaylists(userPlaylistsResponse, playlistIDs);
    // Gather all promises so updatePlaylists will be called only once when all fetching is done
    const fullyUpdatedPlaylistPromiseArray = playlistsToUpdate.map(async playlist => {
      // Get all playlist tracks
      const playlistTrackResponse: SpotifyApi.PlaylistTrackResponse = await this._spotifyProviderService.getTracksByPlaylistID(playlist.id);
      // Map playlists tracks to TrackModels and save them into existing looping playlist variable
      // Save track ids into an array so you can call Metadata fetch later
      const trackIDs: string[] = [];
      playlist.tracks = playlistTrackResponse.items.map((trackResponseObject: SpotifyApi.PlaylistTrackObject) => {
        trackIDs.push(trackResponseObject.track.id);
        return new TrackModel(trackResponseObject.track);
      });

      // Create chunks of tracks because you can get only 100 tracks metadata per request
      const chunksOfTracks = createChunks(config.spotify.spotifyRequestLimit, trackIDs);
      const chunkOfTracksPromises = chunksOfTracks.map(chunk => {
        return this._spotifyProviderService.getMultipleTracksMetadata(chunk);
      });

      // Wait for all track metadata responses ( 1 per 100 song) and add them to playlist
      return Promise.all(chunkOfTracksPromises).then(trackMetadataResponses => {
        this._updateTracksMetadata(trackMetadataResponses, playlist.tracks);
        playlist.loading = false;
        return playlist;
      });
    });

    return Promise.all(fullyUpdatedPlaylistPromiseArray).then(listOfPlaylists => {
      // If we are fetching playlists one by one, since fetching asynchronous , we only want to replace those playlists that we fetched
      const copySources = this.isofiSources.slice();
      for (const fetchedPlaylist of listOfPlaylists) {
        let idx = 0;
        let found = false;
        // Since fetch can be called to existing sources, we need to check whether to add new source or just update it
        // Better to splice then add, instead of assigning a reference
        for (const existingSource of copySources) {
          if (existingSource.id === fetchedPlaylist.id) {
            found = true;
            copySources.splice(idx, 1, fetchedPlaylist);
            break;
          }
          idx++;
        }
        if (!found) {
          copySources.push(fetchedPlaylist);
        }
      }
      this._appStatesService.updateBackgroundFetch(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.INACTIVE));
      this.updateIsofiSources(copySources);
    });
  }

  public async importPlaylistToIsofiById(id: string): Promise<void> {
    const playlist = await this._spotifyProviderService.getPlaylist(id);
    if (playlist) {
      await this.importPlaylistToIsofi(new SpotifyPlaylistModel(playlist));
    }
  }

  public async importPlaylistToIsofi(playlist: SpotifyPlaylistModel): Promise<void> {
    const copyIsofiPlaylists = this.isofiPlaylists.slice();
    var spotifyTracks = await this._spotifyProviderService.getTracksByPlaylistIDWithPaging(playlist.id);
    var tracks = spotifyTracks.map(({track}) => new TrackModel(track));
    // const features = await this._spotifyProviderService.getMultipleTracksMetadata(tracks.map(t => t.id));
    // for(var i = 0; i < tracks.length; i++) {
    //   tracks[i].metadata = features.audio_features[i];
    // }
    
      // Create chunks of tracks because you can get only 100 tracks metadata per request
      const chunksOfTracks = createChunks(config.spotify.spotifyRequestLimit, tracks.map(t => t.id));
      const chunkOfTracksPromises = chunksOfTracks.map(chunk => {
        return this._spotifyProviderService.getMultipleTracksMetadata(chunk);
      });

      // Wait for all track metadata responses ( 1 per 100 song) and add them to playlist
      await Promise.all(chunkOfTracksPromises).then(trackMetadataResponses => {
        for(var i = 0; i < trackMetadataResponses.length; i++) {
          for(var j = 0; j < trackMetadataResponses[i].audio_features.length; j++) {
            tracks[i * 100 + j].metadata = trackMetadataResponses[i].audio_features[j];
          }
        }
      });

    // const newDuration = tracks.reduce((acc, curr) => {
    //   return acc + curr.metadata.duration_ms;
    // }, 0);
    //const playlistStats = new PlaylistStatsModel(tracks.length, Math.floor(newDuration / 1000 / 60), Math.floor(newDuration % 60));
    const newIsofiPlaylist = new IsofiPlaylistModel(
      playlist.id,
      playlist.name,
      this._authService.user.id,
      FilterTypeEnum.BASIC,
      // new AxisFilterModel('danceability', 0, 1),
      new AxisFilterModel('energy', 0, 1),
      // new AxisFilterModel('energy', 0, 1),
      new AxisFilterModel('valence', 0, 1),
      this.listOfBasicFilters[0].presetValue,
      /* sources */ [],
      /* tracks */ tracks,
      //* expanded */ false,
      //* stats */ playlistStats,
    );

    copyIsofiPlaylists.push(newIsofiPlaylist);
    await this.saveAndUpdateCurrentIsofiPlaylist(newIsofiPlaylist);
    this.updateIsofiPlaylists(copyIsofiPlaylists);
    // this.fetchAndUpdateDataInBackground(copyIsofiPlaylists.map(p => p.id));
  }

  /**
   * Tries to load spotify * isofi playlists from storage
   * @returns Returns a promise if background fetch needs to be initiated
   */
  public loadPlaylistsFromLocalStorage(): void {
    const localStorageIsofiSources = localStorage.getItem(LocalStorageEnum.ISOFI_SOURCES);
    const localStorageAllIsofiSources = localStorage.getItem(LocalStorageEnum.ALL_ISOFI_SOURCES);
    const localStorageIsofiPlaylists = localStorage.getItem(LocalStorageEnum.ISOFI_PLAYLISTS);
    const localStorageAllIsofiPlaylists = localStorage.getItem(LocalStorageEnum.ALL_ISOFI_PLAYLISTS);

    let isofiSources: SpotifyPlaylistModel[] = [];
    let isofiPlaylists: IsofiPlaylistModel[] = [];
    let allIsofiPlaylists: IsofiPlaylistObjectType[] = [];
    let allIsofiSources: PlaylistObjectType[] = [];

    if (localStorageIsofiSources) {
      const parsedIsofiSources: PlaylistObjectType[] = JSON.parse(localStorageIsofiSources);

      for (const isofiSource of parsedIsofiSources) {
        if (isofiSource.owner === this._authService.user.id) {
          isofiSources.push(SpotifyPlaylistModel.deserialize(isofiSource));
        } else {
          allIsofiSources.push(isofiSource);
        }
      }
    }

    if (localStorageIsofiPlaylists) {
      const parsedIsofiPlaylists: IsofiPlaylistObjectType[] = JSON.parse(localStorageIsofiPlaylists);
      for (const isofiPlaylist of parsedIsofiPlaylists) {
        if (isofiPlaylist.owner === this._authService.user.id) {
          isofiPlaylists.push(IsofiPlaylistModel.deserialize(isofiPlaylist));
        } else {
          allIsofiPlaylists.push(isofiPlaylist);
        }
      }
    }

    if (localStorageAllIsofiPlaylists) {
      const parsedAllIsofiPlaylists: IsofiPlaylistObjectType[] = JSON.parse(localStorageAllIsofiPlaylists);
      for (const isofiPlaylist of parsedAllIsofiPlaylists) {
        if (isofiPlaylist.owner === this._authService.user.id) {
          isofiPlaylists.push(IsofiPlaylistModel.deserialize(isofiPlaylist));
        } else {
          allIsofiPlaylists.push(isofiPlaylist);
        }
      }
    }

    if (localStorageAllIsofiSources) {
      const parsedAllIsofiSources: PlaylistObjectType[] = JSON.parse(localStorageAllIsofiSources);

      for (const isofiSource of parsedAllIsofiSources) {
        if (isofiSource.owner === this._authService.user.id) {
          isofiSources.push(SpotifyPlaylistModel.deserialize(isofiSource));
        } else {
          allIsofiSources.push(isofiSource);
        }
      }
    }

    this.updateCurrentlyEditingPlaylist(isofiPlaylists.length ? isofiPlaylists[0].id : PlaylistEnums.EMPTY_NAME);
    this.updateIsofiPlaylists(isofiPlaylists);
    this.updateIsofiSources(isofiSources);
    localStorage.setItem(LocalStorageEnum.ALL_ISOFI_PLAYLISTS, JSON.stringify(allIsofiPlaylists));
    localStorage.setItem(LocalStorageEnum.ALL_ISOFI_SOURCES, JSON.stringify(allIsofiSources));

    if (isofiPlaylists.length) {
      this.saveAndUpdateCurrentIsofiPlaylist(isofiPlaylists[0]);
    }
  }

  /**
   * Deletes all playlist data from service
   */
  public logout(): void {
    const allIsofiPlaylists = localStorage.getItem(LocalStorageEnum.ALL_ISOFI_PLAYLISTS);
    const allIsofiSources = localStorage.getItem(LocalStorageEnum.ALL_ISOFI_SOURCES);
    if (allIsofiPlaylists) {
      const parsedAllIsofiPlaylists = JSON.parse(allIsofiPlaylists);
      parsedAllIsofiPlaylists.push(...this.isofiPlaylists);
      localStorage.setItem(LocalStorageEnum.ALL_ISOFI_PLAYLISTS, JSON.stringify(parsedAllIsofiPlaylists));
    } else {
      localStorage.setItem(LocalStorageEnum.ALL_ISOFI_PLAYLISTS, JSON.stringify(this.isofiPlaylists));
    }

    if (allIsofiSources) {
      const parsedAllIsofiSources = JSON.parse(allIsofiSources);
      parsedAllIsofiSources.push(...this.isofiSources);
      localStorage.setItem(LocalStorageEnum.ALL_ISOFI_SOURCES, JSON.stringify(parsedAllIsofiSources));
    } else {
      localStorage.setItem(LocalStorageEnum.ALL_ISOFI_SOURCES, JSON.stringify(this.isofiSources));
    }

    this.updateIsofiPlaylists([]);
    this.updateIsofiSources([]);
    this._suggestions.update([]);
    this.saveAndUpdateCurrentIsofiPlaylist(IsofiPlaylistModel.createEmptyPlaylist());
  }

  /**
   * Removes spotify playlist as source first globally, then for all isofiPlaylists
   * @param playlistID ID of the spotify playlist to remove as source
   */
  public removePlaylistSource(playlist: SpotifyPlaylistModel): SpotifyPlaylistModel {
    const copySources = this.isofiSources.filter(source => source.id !== playlist.id);
    this.updateIsofiSources(copySources);

    // Remove source for all playlists that have selected that source
    this._changeIsofiPlaylists(
      (isofiPlaylist: IsofiPlaylistModel) => {
        isofiPlaylist.sources = isofiPlaylist.sources.filter(source => source !== playlist.id);
      },
      this.isofiPlaylists.map(p => p.id),
    );

    return playlist;
  }

  /**
   * Removes a single source from isofi playlist
   * @param isofiPlaylistID Isofi playlist ID to remove source from
   * @param spotifyPlaylistID Spotify playlist ID to remove as a source
   */
  public removeSourceFromIsofiPlaylist(isofiPlaylist: IsofiPlaylistModel, spotifyPlaylist: SpotifyPlaylistModel): void {
    this._changeIsofiPlaylists(
      (isofiPlaylistToChange: IsofiPlaylistModel) => {
        isofiPlaylistToChange.sources = isofiPlaylistToChange.sources.filter(id => id !== spotifyPlaylist.id);
      },
      [isofiPlaylist.id],
    );
  }

  /**
   * Removes all tracks for currently editing isofi playlist
   */
  public async resetPlaylist(): Promise<boolean> {
    const resetPlaylistResponse = await this._spotifyProviderService.resetPlaylist(this.currentIsofiPlaylist.id);

    if (resetPlaylistResponse.snapshot_id) {
      this._changeIsofiPlaylists((isofiPlaylist: IsofiPlaylistModel) => {
        isofiPlaylist.tracks = [];
      });
      this._suggestions.update([]);
      return true;
    } else return false;
  }

  /**
   * Updates currently editing playlist and calculates stats for new editing playlist
   * @param isofiCurrentEditingPlaylist
   */
   public updateCurrentlyEditingPlaylist(isofiCurrentEditingPlaylist: string): void {
    const newCurrentlyEditingPlaylist = this.isofiPlaylists.find(playlist => playlist.id === isofiCurrentEditingPlaylist);

    if(!newCurrentlyEditingPlaylist) {
      // Playlist not found in isofiPlaylists, so needs to be imported from Spotify
      this.importPlaylistToIsofiById(isofiCurrentEditingPlaylist);
      return;
    }

    if (newCurrentlyEditingPlaylist) {
      newCurrentlyEditingPlaylist.expanded = true;
      this.saveAndUpdateCurrentIsofiPlaylist(newCurrentlyEditingPlaylist);
    } else {
      this.saveAndUpdateCurrentIsofiPlaylist(IsofiPlaylistModel.createEmptyPlaylist());
    }
  }
  
  // NB: Not currently functional, so disabled
  // /**
  //  * Clears currently editing playlist
  //  * @param isofiCurrentEditingPlaylist
  //  */
  // public deselectCurrentlyEditingPlaylist(): void {
  //   this.updateCurrentlyEditingPlaylist(PlaylistEnums.EMPTY_NAME);
  //   // this.saveAndUpdateCurrentIsofiPlaylist(IsofiPlaylistModel.createEmptyPlaylist());
  // }

  /**
   * Function saves current isofi playlist. Currently has no function but could be useful when save should need side effects
   * @param isofiPlaylist
   */
  public saveAndUpdateCurrentIsofiPlaylist(isofiPlaylist: IsofiPlaylistModel): void {
    this._currentIsofiPlaylist.update(isofiPlaylist);
  }

  /*
   * Updates Isofi playlists and saves them to local storage and service
   * @param isofiPlaylists
   */
  public updateIsofiPlaylists(isofiPlaylists: IsofiPlaylistModel[]): void {
    localStorage.setItem(LocalStorageEnum.ISOFI_PLAYLISTS, JSON.stringify(isofiPlaylists));
    this._isofiPlaylists.update(isofiPlaylists);
  }

  /**
   * Updates Spotify playlists and saves them to local storage and service
   * @param spotifyPlaylists
   */
  public updateIsofiSources(isofiSources: SpotifyPlaylistModel[]): void {
    localStorage.setItem(LocalStorageEnum.ISOFI_SOURCES, JSON.stringify(isofiSources));
    this._isofiSources.update(isofiSources);
  }

  /**
   * Updates track suggestions
   * @param value Array of new track suggestions
   */
  public updateSuggestions(value: TrackModel[]): void {
    this._suggestions.update(value);
  }

  /* Private functions */

  /**
   * Creates a copy of Spotify playlists, finds the playlists to change and calls callback. Saves changes to service.
   * @param callback Function that gets called with playlist as argument
   * @param playlistIDs Array of spotify playlist IDs (strings) to change
   */
  private _changeIsofiSources(callback: Function, playlistIDs: string[]): void {
    const isofiSources = this.isofiSources.slice();

    for (const spotifyPlaylist of isofiSources) {
      if (playlistIDs.includes(spotifyPlaylist.id)) {
        callback(spotifyPlaylist);
      }
    }

    this.updateIsofiSources(isofiSources);
  }

  /**
   *   /**
   * Creates a copy of Isofi playlists, finds the playlists to change and calls callback for each.  Saves changes to service.
   * @param callback Function that gets called with playlist as argument
   * @param playlistIDs Array of Isofi playlist IDs to change. If no ID is provided, it takes currently editing ID from state.
   */
  private _changeIsofiPlaylists(callback: Function, playlistIDs: string[] = [this.currentIsofiPlaylist.id]): void {
    const copiedIsofiPlaylists = this.isofiPlaylists.slice();
    let currentlyEditingPlaylist;
    for (const isofiPlaylist of copiedIsofiPlaylists) {
      if (playlistIDs.includes(isofiPlaylist.id)) {
        const newDuration = isofiPlaylist.tracks.reduce((acc, curr) => {
          return acc + curr.metadata.duration_ms;
        }, 0);
        isofiPlaylist.stats = new PlaylistStatsModel(isofiPlaylist.tracks.length, Math.floor(newDuration / 1000 / 60), Math.floor(newDuration % 60));
        callback(isofiPlaylist);
        if (isofiPlaylist.id === this.currentIsofiPlaylist.id) {
          currentlyEditingPlaylist = IsofiPlaylistModel.deserialize(isofiPlaylist);
          this.saveAndUpdateCurrentIsofiPlaylist(currentlyEditingPlaylist);
        }
        break;
      }
    }

    this.updateIsofiPlaylists(copiedIsofiPlaylists);
  }

  /**
   * Method for selecting closest songs based on selected axis values (Basic filter)
   * @param keyX Track metadata x key
   * @param keyY Track metadata y key
   * @param valueX Value of clicked x coordinate  (already converted from pixel to graph value)
   * @param valueY Value of clicked y coordinate  (already converted from pixel to graph value)
   * @returns Returns array of closest track suggestions (number varies based on config)
   */
  private _getNearbySongs(keyX: keyof TrackMetadataModel, keyY: keyof TrackMetadataModel, valueX: number, valueY: number): TrackModel[] {
    const selectedSpotifyPlaylists = this.isofiSources.filter(s => this.currentIsofiPlaylist.sources.includes(s.id));
    const songPool = selectedSpotifyPlaylists.reduce((tracks: TrackModel[], playlist: SpotifyPlaylistModel) => {
      for (const newTrack of playlist.tracks) {
        let duplicate = false;
        for (const existingTrack of tracks) {
          if (existingTrack.id === newTrack.id) {
            duplicate = true;
            break;
          }
        }

        if (!duplicate) {
          tracks.push(newTrack);
        }
      }
      return tracks;
    }, []);

    const closestTracks = songPool.filter(track => {
      if (
        track.metadata[keyX] >= this.currentIsofiPlaylist.filterX.minValue &&
        track.metadata[keyX] <= this.currentIsofiPlaylist.filterX.maxValue &&
        track.metadata[keyY] >= this.currentIsofiPlaylist.filterY.minValue &&
        track.metadata[keyY] <= this.currentIsofiPlaylist.filterY.maxValue
      ) {
        return true;
      } else return false;
    });

    closestTracks.sort((a, b) => {
      const distanceA = Math.sqrt(Math.pow(valueX - a.metadata[keyX], 2) + Math.pow(valueY - a.metadata[keyY], 2));
      const distanceB = Math.sqrt(Math.pow(valueX - b.metadata[keyX], 2) + Math.pow(valueY - b.metadata[keyY], 2));
      return distanceA - distanceB;
    });

    return closestTracks.slice(0, config.spotify.numberOfSuggestions);
  }

  /**
   * Updates metadata for provided songs
   * @param metadataResponses Metadata responses from Spotify
   * @param tracks List of tracks to update
   */
  private _updateTracksMetadata(metadataResponses: any[], tracks: TrackModel[]) {
    for (const metadataResponse of metadataResponses) {
      for (const metadata of metadataResponse.audio_features) {
        for (const track of tracks) {
          if (track.id === metadata.id) {
            track.metadata = new TrackMetadataModel(metadata);
            break;
          }
        }
      }
    }
  }
}

export const PlaylistServiceContext = React.createContext<PlaylistService | undefined>(undefined);

export const PlaylistServiceHOC = (props: ServiceHOCPropsType) => {
  const appStatesService = useContext(AppStatesServiceContext);
  const authService = useContext(AuthServiceContext);
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);
  if (!appStatesService || !authService || !spotifyProviderService) throw new Error('Missing context');

  const service = new PlaylistService(
    appStatesService,
    authService,
    spotifyProviderService,
    useStateForServices<SpotifyPlaylistModel[]>([]),
    useStateForServices<IsofiPlaylistModel[]>([]),
    useStateForServices<TrackModel[]>([]),
    useStateForServices<IsofiPlaylistModel>(IsofiPlaylistModel.createEmptyPlaylist()),
  );

  return <PlaylistServiceContext.Provider value={service}>{props.children}</PlaylistServiceContext.Provider>;
};
