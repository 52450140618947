import { /* AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext,*/ SpotifyProviderServiceContext } from '../../../services';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './index.scss';

export type ImportPlaylistModalContentProps = {
  buttonText: string;
  defaultValue?: string;
  submit: Function;
  labelText: string;
  // placeholder: string;
};

export const ImportPlaylistModalContent = (props: ImportPlaylistModalContentProps) => {
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);

  // const [playlistName, setPlaylistName] = useState(props.defaultValue ? props.defaultValue : '');
  const [playlistId, setPlaylistId] = useState(props.defaultValue ? props.defaultValue : '');

  const [playlists, setPlaylists] = useState<any[]>([]);
  const fetchPlaylists = useCallback(async (): Promise<void> => {
    // const playlistResponse = await spotifyProviderService?.getUserPlaylists();
    // // const spotifyPlaylists = getNonSelectedPlaylists(playlistResponse.items, playlistService.isofiSources);
    // // setSpotifyPlaylists(spotifyPlaylists);
    // // setPageState(AppStatesEnum.READY);
    // // if (playlistResponse) {
    // //   setPageState(AppStatesEnum.READY);
    // // } else {
    // //   router.push(config.routes.home);
    // // }
    
    // setPlaylists(playlistResponse?.items ?? []);
    setPlaylists(await spotifyProviderService?.getUserPlaylistsWithPaging() ?? []);
  }, [spotifyProviderService/*, router, playlistService.isofiSources, getNonSelectedPlaylists*/]);

  useEffect(() => {
    fetchPlaylists();
  },[fetchPlaylists]);

  return (
    <div className="isofi-playlist-name-modal-container">
      <Form onSubmit={event => props.submit(event, playlistId)}>
        <Form.Label className="article-text-lg3">{props.labelText}</Form.Label>
        {/* <Form.Control
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => setPlaylistName(event.target.value)}
          size="lg"
          type="text"
          // placeholder={props.placeholder}
          value={playlistName}
        /> */}
        <Form.Select onInput={(event: React.ChangeEvent<HTMLSelectElement>) => setPlaylistId(event.target.value)}>
          <option value={""}>{'<Select a playlist>'}</option>
          {/* {playlists.map((playlist: any) => <option key={playlist}>{JSON.stringify(playlist)}</option>)} */}
          {/* {playlists.map(({collaborative, description, external_urls, href, images, ...playlist}: any) => <option key={playlist}>{JSON.stringify(playlist)}</option>)} */}
          {playlists.map(({id, name}) => <option key={id} value={id}>{name}</option>)}
          {/* <option value="">{'<Select Playlist>'}</option>*/}
        </Form.Select>
        <Button disabled={props.defaultValue === playlistId} type="submit" variant="primary">
          {props.buttonText}
        </Button>
      </Form>
    </div>
  );
};
