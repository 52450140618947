// import { useContext } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Alert, Button } from 'react-bootstrap';
// import { ADAuthContext } from '../../services';
// import './index.scss';

// export const AuthOverlay = () => {
//     const adAuth = useContext(ADAuthContext);
//     const { pathname } = useLocation() ?? { pathname: undefined };
//     if(!adAuth) throw new Error('Missing ADAuth context');
//     const { authenticated } = adAuth;
//     if(pathname?.startsWith('/.auth')) return null; // Skip overlay within .auth views
//     if(adAuth.hasRole('therapist')) return null; // We are authenticated with a therapist role, so we don't have to show the ADAuth
//     if(adAuth.hasRole('administrator')) return null; // We are authenticated with an adminstrator role, so we don't have to show the ADAuth
//     if(authenticated && !adAuth.hasRole('therapist')) {
//         console.warn(`User does not have the required role to access this application. User roles: ${adAuth.roles}`);
//         return <div className="auth-overlay">
//                 <div className="auth-overlay-content">
//                     You must be a registered therapist to access this application.  Please reach out to us on <a href="mailto:russell@isofi.app">russell@isofi.app</a> if you wish to arrange access.

//                     <div>
//                         <Button variant="success" onClick={() => adAuth.logout()}>Logout</Button>
//                     </div>
//                 </div>
//             </div>;
//     }
//     return (
//         <div className="auth-overlay">
//             <div className="auth-overlay-content">
//                 <Alert variant="danger">
//                     <Alert.Heading>You must be logged in to access this application</Alert.Heading>
//                 </Alert>
//                 <div>
//                     <Button variant="success" onClick={() => adAuth.authorize()}>Login</Button>
//                 </div>
//             </div>
//         </div>
//     );
// };

export const AuthOverlay = ():JSX.Element => <></>;

// export default AuthOverlay;