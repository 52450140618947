import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { config } from '../../../environments/config';
import { ModalEnums } from '../../../data';
import { IsofiPlaylistModel } from '../../../data/index';
import { AppStatesServiceContext, PlaylistServiceContext } from '../../../services';
import { IsofiPlaylistNameModalContent } from '../';

export const SetNewPlaylistNameModal = () => {
  const appStatesService = useContext(AppStatesServiceContext);
  const playlistService = useContext(PlaylistServiceContext);

  if (!appStatesService || !playlistService) throw new Error('Missing context!');

  const router = useHistory();

  const setNewIsofiPlaylistName = async (event: React.FormEvent, name: string): Promise<void> => {
    event.preventDefault();
    if (IsofiPlaylistModel.validateName(name, playlistService.isofiPlaylists)) {
      const playlist = await playlistService.createNewIsofiPlaylist(name);
      appStatesService.updateModal(ModalEnums.NO_MODAL);
      router.push(`${config.routes.playlistBuilderBase}/${playlist.id}`);
    }
  };

  return (
    <IsofiPlaylistNameModalContent
      buttonText="Add new playlist"
      submit={setNewIsofiPlaylistName}
      labelText="Enter playlist name"
      placeholder="Type in playlist name here"
    />
  );
};
