import React from 'react';
import { ChartEvent, ActiveElement, Chart } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { PlaylistService, MapperService, TemporaryStatesService, PlayerService } from '../../../../services';
import { ChartOptionsType, ChartValueType, FilterTypeEnum, IsofiPlaylistModel, PlayLocationEnum, ScatterDatasetEnum } from '../../../../data';
import './index.scss';

interface IScatterChartProps {
  playerService: PlayerService;
  playlistService: PlaylistService;
  playlistID: string;
  resetChart: boolean;
  temporaryStatesService: TemporaryStatesService;
  showCircle?: boolean;
}

interface IScatterChartState {
  isofiPlaylist: IsofiPlaylistModel;
  data: any;
  options: ChartOptionsType;
  numberOfSources: number;
  referencePointNative: { x: number; y: number; diameter: number };
  chartDrawingRef: NodeJS.Timeout | undefined;
}

export class ScatterChart extends React.Component<IScatterChartProps, IScatterChartState> {
  constructor(props: IScatterChartProps) {
    super(props);
    Chart.register(this._pointNumbersPlugin);
    this.state = {
      options: {
        // responsive: true,
        // maintainAspectRatio: true,
        scales: {
          y: {
            title: { display: true, text: '', color: '#FFF' },
            max: 1,
            min: 0,
            grid: {
              borderColor: '#696666',
              color: '#696666',
              borderWidth: 4,
              lineWidth: 2,
            },
            ticks: {
              stepSize: 0.1,
              font: {
                family: 'Inter',
                size: 14,
                style: 'normal',
                weight: 'regular',
                lineHeight: 1.2,
              },
            },
          },
          x: {
            title: { display: true, text: '', color: '#FFF' },
            max: 1,
            min: 0,
            grid: {
              borderColor: '#696666',
              color: '#696666',
              borderWidth: 4,
              lineWidth: 2,
            },
            ticks: {
              stepSize: 0.1,
              font: {
                family: 'Inter',
                size: 14,
                style: 'normal',
                weight: 'regular',
                lineHeight: 1.2,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          pointNumbersPlugin: true,
        },
        // animation: {
        //   duration: 0,
        // },
        onClick: this._handleChartOffClick,
        onHover: this._handleChartElementHover,
      },
      data: {
        datasets: [
          {
            label: 'Songs in a playlist',
            data: [],
            pointHoverRadius: 15,
            showLine: false,
            order: 3,
            pointRadius: (context: any) => {
              if (context.raw && context.raw.song.id === this.props.temporaryStatesService.currentlyHoveredPlaylistTrack) {
                return 15;
              } else {
                return 10;
              }
            },

            pointBackgroundColor: (context: any) => {
              if (context.raw && context.raw.song.id === this.props.temporaryStatesService.currentlyHoveredPlaylistTrack) {
                return '#6e2926';
              } else {
                return '#EB9A99';
              }
            },
          },
          {
            label: 'Suggestions',
            data: [],
            pointHoverRadius: 10,
            showLine: false,
            order: 2,
            pointRadius: (context: any) => {
              if (context.raw && context.raw.song.id === this.props.temporaryStatesService.currentlyHoveredSuggestion) {
                return 10;
              } else {
                return 5;
              }
            },
            pointBackgroundColor: (context: any) => {
              if (context.raw && context.raw.song.id === this.props.temporaryStatesService.currentlyHoveredSuggestion) {
                return '#EB9A99';
              } else {
                return '#fff';
              }
            },
          },
          {
            label: 'Current reference point',
            backgroundColor: 'transparent',
            data: [],
            fill: false,
            borderWidth: 2,
            showLines: false,
            borderColor: '#EB9A99',
          },
        ],
      },
      isofiPlaylist: IsofiPlaylistModel.createEmptyPlaylist(),
      numberOfSources: 0,
      referencePointNative: { x: 0, y: 0, diameter: 0 },
      chartDrawingRef: undefined,
    };
  }

  private _resizeDebounceTimeout?: NodeJS.Timeout;

  private _pointNumbersPlugin = {
    id: 'pointNumbersPlugin',
    afterDraw: (chart: any) => {
      if (chart) {
        this._drawOnChart(chart);
      }
    },

    resize: (chart: any) => {
      if (this._resizeDebounceTimeout) {
        clearTimeout(this._resizeDebounceTimeout);
      }
      this._resizeDebounceTimeout = setTimeout(() => {
        if (
          chart &&
          chart.scales &&
          chart.scales.x &&
          chart.scales.y &&
          this.state.data.datasets[ScatterDatasetEnum.REFERENCE_POINT].data.length &&
          this.state.data.datasets[ScatterDatasetEnum.SUGGESTIONS].data.length
        ) {
          const { x, y } = this.state.data.datasets[ScatterDatasetEnum.REFERENCE_POINT].data[0];
          const nativeXCoordinate = chart.scales.x.getPixelForValue(x);
          const nativeYCoordinate = chart.scales.y.getPixelForValue(y);

          const suggestionsWithNativeCoordinates = this.state.data.datasets[ScatterDatasetEnum.SUGGESTIONS].data.map((suggestion: any) => {
            return {
              x: chart.scales.x.getPixelForValue(suggestion.x),
              y: chart.scales.y.getPixelForValue(suggestion.y),
            };
          });

          this._calculatePointDiameter(nativeXCoordinate, nativeYCoordinate, suggestionsWithNativeCoordinates);
        }
      }, 10);
    },
  };

  private _drawOnChart(chart: any) {
    chart._metasets[ScatterDatasetEnum.PLAYLIST].data.forEach((point: any, pointIndex: number) => {
      const x = point.getCenterPoint().x;
      const y = point.getCenterPoint().y;
      let fontSize = 12;
      chart.ctx.textBaseline = 'middle';
      chart.ctx.textAlign = 'center';
      chart.ctx.font = `bold ${fontSize} Inter`;
      chart.ctx.fillStyle = '#000';
      const text = `${pointIndex < 9 ? '0' : ''}${pointIndex + 1}`;
      chart.ctx.fillText(text, x, y);
    });
  }

  public componentDidMount() {
    const copyOptions = Object.assign({}, this.state.options);
    const copyData = Object.assign({}, this.state.data);
    copyData.datasets[ScatterDatasetEnum.PLAYLIST].data = this._updatePlaylistChart();
    this._setMinMax(copyOptions);
    this._setAxisNames(copyOptions);

    this.setState({
      data: copyData,
      isofiPlaylist: IsofiPlaylistModel.deserialize(this.props.playlistService.currentIsofiPlaylist),
      numberOfSources: this.props.playlistService.currentIsofiPlaylist.sources.length,
      options: copyOptions,
    });
  }

  public componentDidUpdate(prevProps: IScatterChartProps) {
    // Listen for reset chart boolean which is sent through props by Playlist builder
    if (!prevProps.resetChart && this.props.resetChart) {
      const copyData = Object.assign({}, this.state.data);
      copyData.datasets.forEach((dataset: any) => (dataset.data = []));
      this.setState({
        data: copyData,
        isofiPlaylist: IsofiPlaylistModel.deserialize(this.props.playlistService.currentIsofiPlaylist),
      });
    }

    // Listen for param changes
    else if (prevProps.playlistID !== this.props.playlistID || this.props.playlistService.currentIsofiPlaylist.id !== this.state.isofiPlaylist.id) {
      const copyOptions = Object.assign({}, this.state.options);
      this._setMinMax(copyOptions);
      const copyData = Object.assign({}, this.state.data);
      copyData.datasets[ScatterDatasetEnum.SUGGESTIONS].data = [];
      copyData.datasets[ScatterDatasetEnum.REFERENCE_POINT].data = [];
      copyData.datasets[ScatterDatasetEnum.PLAYLIST].data = this._updatePlaylistChart();

      this.setState({
        data: copyData,
        isofiPlaylist: IsofiPlaylistModel.deserialize(this.props.playlistService.currentIsofiPlaylist),
        numberOfSources: this.props.playlistService.currentIsofiPlaylist.sources.length,
        options: copyOptions,
      });
    }

    // Listen for playlist track number changes: add / delete
    else if (this.state.isofiPlaylist.tracks.length !== this.props.playlistService.currentIsofiPlaylist.tracks.length) {
      const copyData = Object.assign({}, this.state.data);
      copyData.datasets[ScatterDatasetEnum.PLAYLIST].data = this._updatePlaylistChart();
      this.setState({
        data: copyData,
        isofiPlaylist: IsofiPlaylistModel.deserialize(this.props.playlistService.currentIsofiPlaylist),
      });
    }

    // Listen for playlist sources changes, and calculate min/max for chart if changed
    else if (this.state.numberOfSources !== this.props.playlistService.currentIsofiPlaylist.sources.length) {
      const copyOptions = Object.assign({}, this.state.options);
      this._setMinMax(copyOptions);
      this.setState({
        numberOfSources: this.props.playlistService.currentIsofiPlaylist.sources.length,
        options: copyOptions,
      });
    }

    //Listen for Basic preset type changes
    else if (
      this.props.playlistService.currentIsofiPlaylist.filterType !== this.state.isofiPlaylist.filterType ||
      this.props.playlistService.currentIsofiPlaylist.basicPreset !== this.state.isofiPlaylist.basicPreset ||
      this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey !== this.state.isofiPlaylist.filterX.metadataKey ||
      this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey !== this.state.isofiPlaylist.filterY.metadataKey ||
      this.props.playlistService.currentIsofiPlaylist.filterX.minValue !== this.state.isofiPlaylist.filterX.minValue ||
      this.props.playlistService.currentIsofiPlaylist.filterX.maxValue !== this.state.isofiPlaylist.filterX.maxValue ||
      this.props.playlistService.currentIsofiPlaylist.filterY.minValue !== this.state.isofiPlaylist.filterY.minValue ||
      this.props.playlistService.currentIsofiPlaylist.filterY.maxValue !== this.state.isofiPlaylist.filterY.maxValue
    ) {
      const copyOptions = Object.assign({}, this.state.options);
      this._setMinMax(copyOptions);
      const copyData = Object.assign({}, this.state.data);
      copyData.datasets[ScatterDatasetEnum.PLAYLIST].data = this._updatePlaylistChart();
      copyData.datasets[ScatterDatasetEnum.SUGGESTIONS].data = [];
      copyData.datasets[ScatterDatasetEnum.REFERENCE_POINT].data = [];

      this.setState({
        data: copyData,
        isofiPlaylist: IsofiPlaylistModel.deserialize(this.props.playlistService.currentIsofiPlaylist),
        options: copyOptions,
      });
    }
  }

  public shouldComponentUpdate(nextProps: Readonly<IScatterChartProps>, nextState: Readonly<IScatterChartState>) {
    if (nextProps.temporaryStatesService.currentlyHoveredPlaylistTrack !== this.props.temporaryStatesService.currentlyHoveredPlaylistTrack) {
      return true;
    }

    if (nextProps.temporaryStatesService.currentlyHoveredSuggestion !== this.props.temporaryStatesService.currentlyHoveredSuggestion) {
      return true;
    }

    if (nextProps.playlistService.currentIsofiPlaylist.id !== this.state.isofiPlaylist.id) {
      return true;
    }

    if (nextProps.playlistService.currentIsofiPlaylist.tracks.length !== this.state.isofiPlaylist.tracks.length) {
      return true;
    }

    if (nextState.referencePointNative.x !== this.state.referencePointNative.x || nextState.referencePointNative.y !== this.state.referencePointNative.y) {
      return true;
    }

    if (nextProps.resetChart !== this.props.resetChart) {
      return true;
    }

    if (
      nextProps.playlistService.currentIsofiPlaylist.filterType !== this.state.isofiPlaylist.filterType ||
      nextProps.playlistService.currentIsofiPlaylist.basicPreset !== this.state.isofiPlaylist.basicPreset ||
      nextProps.playlistService.currentIsofiPlaylist.filterX.metadataKey !== this.state.isofiPlaylist.filterX.metadataKey ||
      nextProps.playlistService.currentIsofiPlaylist.filterY.metadataKey !== this.state.isofiPlaylist.filterY.metadataKey ||
      nextProps.playlistService.currentIsofiPlaylist.filterX.minValue !== this.state.isofiPlaylist.filterX.minValue ||
      nextProps.playlistService.currentIsofiPlaylist.filterX.maxValue !== this.state.isofiPlaylist.filterX.maxValue ||
      nextProps.playlistService.currentIsofiPlaylist.filterY.minValue !== this.state.isofiPlaylist.filterY.minValue ||
      nextProps.playlistService.currentIsofiPlaylist.filterY.maxValue !== this.state.isofiPlaylist.filterY.maxValue
    ) {
      return true;
    }

    return false;
  }

  private _calculatePointDiameter = (referenceX: number, referenceY: number, suggestions: { [key: string]: number }[]) => {
    let maxX = 0;
    let maxY = 0;
    for (const point of suggestions) {
      if (Math.abs(referenceX - point.x) > maxX) {
        maxX = Math.abs(referenceX - point.x);
      }

      if (Math.abs(referenceY - point.y) > maxY) {
        maxY = Math.abs(referenceY - point.y);
      }
    }
    const diff = maxX > maxY ? maxX : maxY;
    this.setState({
      referencePointNative: {
        x: referenceX,
        y: referenceY,
        diameter: diff * Math.sqrt(2) * 2 + 10,
      },
    });
  };

  private _handleChartElementHover = (event: ChartEvent, activeElements: ActiveElement[], chart: Chart) => {
    if (activeElements.length === 0) {
      // Update function has check not to change state if state is the same
      // For some reason it ignores empty string
      if (this.props.temporaryStatesService.currentlyHoveredSuggestion !== '') {
        this.props.temporaryStatesService.updateCurrentlyHoveredSuggestion('');
      }

      if (this.props.temporaryStatesService.currentlyHoveredPlaylistTrack !== '') {
        this.props.temporaryStatesService.updateCurrentlyHoveredPlaylistTrack('');
      }
    } else {
      if (activeElements[0].datasetIndex === ScatterDatasetEnum.SUGGESTIONS) {
        if (
          this.props.temporaryStatesService.currentlyHoveredSuggestion !==
          this.state.data.datasets[ScatterDatasetEnum.SUGGESTIONS].data[activeElements[0].index].song.id
        ) {
          this.props.temporaryStatesService.updateCurrentlyHoveredSuggestion(
            this.state.data.datasets[ScatterDatasetEnum.SUGGESTIONS].data[activeElements[0].index].song.id,
          );
        }
      } else if (activeElements[0].datasetIndex === ScatterDatasetEnum.PLAYLIST) {
        if (
          this.props.temporaryStatesService.currentlyHoveredPlaylistTrack !==
          this.state.data.datasets[ScatterDatasetEnum.PLAYLIST].data[activeElements[0].index].song.id
        ) {
          this.props.temporaryStatesService.updateCurrentlyHoveredPlaylistTrack(
            this.state.data.datasets[ScatterDatasetEnum.PLAYLIST].data[activeElements[0].index].song.id,
          );
        }
      }
    }
  };

  private _handleChartOffClick = (event: ChartEvent, activeElements: any[], chart: Chart) => {
    if (activeElements.length) {
      if (activeElements[0].datasetIndex === ScatterDatasetEnum.PLAYLIST) {
        if (activeElements[0].element.$context.raw.song.id) {
          if (this.props.playerService.spotifyPlayer) {
            this.props.playerService.playTrack(activeElements[0].element.$context.raw.song, PlayLocationEnum.PLAYLIST);
          }
        }
      } else if (activeElements[0].datasetIndex === ScatterDatasetEnum.SUGGESTIONS) {
        if (activeElements[0].element.$context.raw.song.id) {
          if (this.props.playerService.spotifyPlayer) {
            this.props.playerService.playTrack(activeElements[0].element.$context.raw.song, PlayLocationEnum.SUGGESTIONS);
          }
        }
      }
    } else {
      if (event.x && event.y) {
        const valueX = chart.scales.x.getValueForPixel(event.x);
        const valueY = chart.scales.y.getValueForPixel(event.y);

        if (valueX && valueY) {
          const mappedSuggestions = MapperService.mapTracksToChartDataset(
            this.props.playlistService.getAndSetSuggestions(valueX, valueY),
            this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey,
            this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey,
          );
          const copyData = Object.assign({}, this.state.data);
          copyData.datasets[ScatterDatasetEnum.REFERENCE_POINT].data = [{ x: valueX, y: valueY }];
          copyData.datasets[ScatterDatasetEnum.SUGGESTIONS].data = mappedSuggestions;

          const suggestionsWithNativeCoordinates = mappedSuggestions.map(suggestion => {
            return {
              x: chart.scales.x.getPixelForValue(suggestion.x),
              y: chart.scales.y.getPixelForValue(suggestion.y),
            };
          });

          this.setState(
            {
              data: copyData,
            },
            () => {
              if (event.x && event.y) {
                this._calculatePointDiameter(event.x, event.y, suggestionsWithNativeCoordinates);
              }
            },
          );
        }
      }
    }
  };

  private _getAxisNames = (): { [key: string]: string } => {
    return {
      axisXName: MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey),
      axisYName: MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey),
    };
  };

  private _setMinMax = (options: ChartOptionsType): void => {
    let minX, maxX, minY, maxY;

    if (this.props.playlistService.currentIsofiPlaylist.filterType === FilterTypeEnum.BASIC) {
      ({ minX, maxX, minY, maxY } = this.props.playlistService.calculateMinMax(
        this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey,
        this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey,
      ));
      options.scales.x.title.text = MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey);
      options.scales.y.title.text = MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey);
    } else {
      minX = this.props.playlistService.currentIsofiPlaylist.filterX.minValue;
      maxX = this.props.playlistService.currentIsofiPlaylist.filterX.maxValue;
      minY = this.props.playlistService.currentIsofiPlaylist.filterY.minValue;
      maxY = this.props.playlistService.currentIsofiPlaylist.filterY.maxValue;
      options.scales.x.title.text = MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey);
      options.scales.y.title.text = MapperService.mapSpotifyMetadataValueToAlias(this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey);
    }

    options.scales.x.min = minX;
    options.scales.x.max = maxX;
    options.scales.x.ticks.stepSize = maxX / 10;
    options.scales.y.min = minY;
    options.scales.y.max = maxY;

    options.scales.y.ticks.stepSize = maxY / 10;
  };

  private _setAxisNames = (options: ChartOptionsType): void => {
    const { axisXName, axisYName } = this._getAxisNames();
    options.scales.x.title.text = axisXName;
    options.scales.y.title.text = axisYName;
  };

  private _updatePlaylistChart(): ChartValueType[] {
    return this.props.playlistService.currentIsofiPlaylist.tracks.map(track => {
      return {
        x: track.metadata[this.props.playlistService.currentIsofiPlaylist.filterX.metadataKey],
        y: track.metadata[this.props.playlistService.currentIsofiPlaylist.filterY.metadataKey],
        song: track,
      };
    });
  }

  render() {
    return (
      <div className="chart-container">
        <Scatter id="scatter-chart" data={this.state.data} options={this.state.options}></Scatter>
        {this.props.showCircle && this.state.data.datasets[ScatterDatasetEnum.SUGGESTIONS].data.length ? (
          <div className="diameter-container">
            <span
              className="circle"
              style={{
                left: this.state.referencePointNative.x - this.state.referencePointNative.diameter / 2,
                top: this.state.referencePointNative.y - this.state.referencePointNative.diameter / 2,
                width: this.state.referencePointNative.diameter,
                height: this.state.referencePointNative.diameter,
              }}></span>
          </div>
        ) : null}
      </div>
    );
  }
}
