import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { MdRefresh } from 'react-icons/md';
import { config } from '../../environments/config';
import { AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext, SpotifyProviderServiceContext } from '../../services';
import { IconSpinner, Spinner } from '../../components';
import { AppStatesEnum, BackgroundFetchStateEnum, BackgroundPlaylistFetchModel, SpotifyImageModel, SpotifyPlaylistModel } from '../../data';

import './index.scss';

export type PlaylistSourcesPropsType = {};

export const PlaylistSources = (props: PlaylistSourcesPropsType) => {
  const authService = useContext(AuthServiceContext);
  const appStatesService = useContext(AppStatesServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);

  if (!authService || !appStatesService || !playlistService || !spotifyProviderService) throw new Error('Missing context');

  const [isofiSourceCount, setIsofiSourceCount] = useState(playlistService.isofiSources.length);
  const [spotifyPlaylists, setSpotifyPlaylists] = useState<SpotifyPlaylistModel[]>([]);
  const [pageState, setPageState] = useState(AppStatesEnum.LOADING);
  const router = useHistory();

  const getNonSelectedPlaylists = useCallback((playlists: any[], sources: SpotifyPlaylistModel[]): SpotifyPlaylistModel[] => {
    return playlists
      .map(playlistResponse => new SpotifyPlaylistModel(playlistResponse))
      .filter(spotifyPlaylist => {
        let found = false;

        if (spotifyPlaylist.description.indexOf(config.spotify.playlistDescriptionIdentifier) !== -1) {
          return false;
        }

        for (const isofiSource of sources) {
          if (isofiSource.description.indexOf(config.spotify.playlistDescriptionIdentifier) !== -1) {
            found = true;
            return false;
          }

          if (isofiSource.id === spotifyPlaylist.id) {
            found = true;
            return false;
          }
        }

        return !found;
      });
  }, []);

  const fetchPlaylists = useCallback(async (): Promise<void> => {
    const playlists = await spotifyProviderService.getUserPlaylistsWithPaging();
    const spotifyPlaylists = getNonSelectedPlaylists(playlists, playlistService.isofiSources);
    setSpotifyPlaylists(spotifyPlaylists);
    setPageState(AppStatesEnum.READY);
    if (playlists) {
      setPageState(AppStatesEnum.READY);
    } else {
      router.push(config.routes.home);
    }
  }, [spotifyProviderService, router, playlistService.isofiSources, getNonSelectedPlaylists]);

  const changeUser = (): void => {
    playlistService.logout();
    authService.loginWithAnotherAccount();
  };

  const refreshAllSources = () => {
    appStatesService.updateBackgroundFetch(
      new BackgroundPlaylistFetchModel(
        BackgroundFetchStateEnum.INITIATED,
        playlistService.isofiSources.map(p => p.id),
      ),
    );
  };

  const addSource = (source: SpotifyPlaylistModel) => {
    const copySpotifyPlaylists = spotifyPlaylists.slice();
    for (const playlist of copySpotifyPlaylists) {
      if (playlist.id === source.id) {
        playlist.loading = true;
        break;
      }
    }
    setSpotifyPlaylists(copySpotifyPlaylists);
    playlistService.addPlaylistSource(source);
  };

  const refreshSource = (source: SpotifyPlaylistModel) => {
    appStatesService.updateBackgroundFetch(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.INITIATED, [source.id]));
  };

  const removeSource = (source: SpotifyPlaylistModel) => {
    playlistService.removePlaylistSource(source);
    const copySpotifyPlaylists = spotifyPlaylists.slice();
    copySpotifyPlaylists.push(source);
    setSpotifyPlaylists(copySpotifyPlaylists);
  };

  useEffect(() => {
    if (isofiSourceCount !== playlistService.isofiSources.length) {
      setIsofiSourceCount(playlistService.isofiSources.length);
      setSpotifyPlaylists(getNonSelectedPlaylists([...playlistService.isofiSources, ...spotifyPlaylists], playlistService.isofiSources));
    }
  }, [isofiSourceCount, spotifyPlaylists, playlistService.isofiSources, getNonSelectedPlaylists]);

  // Get playlists on init
  useEffect(() => {
    if (pageState === AppStatesEnum.LOADING) {
      setPageState(AppStatesEnum.LOADED);
      fetchPlaylists();
    }
  }, [pageState, appStatesService.playlistsLoaded, fetchPlaylists, spotifyProviderService]);

  return (
    <div className="playlist-sources-container">
      {pageState === AppStatesEnum.READY ? (
        <div className="d-flex flex-wrap holder flex-column">
          <div className="title article-title-lg1">Playlist sources (Spotify)</div>
          <div className="profile-container d-flex">
            <img src={authService.user.profilePicture} alt="profile" />
            <div className="profile-info d-flex flex-column align-items-start justify-content-between">
              <span className="article-text-lg4 username">{authService.user.name}</span>
              <div className="d-flex flex-column">
                <Button onClick={changeUser} className="section-text-sm3" variant="primary">
                  Change user
                </Button>
                <Button onClick={refreshAllSources} className="section-text-sm3" variant="secondary">
                  Refresh all sources
                </Button>
              </div>
            </div>
          </div>

          <div className="playlist-container d-flex flex-column selected-sources">
            <div className="title article-title-lg1">Selected sources</div>
            {playlistService.isofiSources.map(playlist => {
              return (
                <div className="tracks-container d-flex justify-content-between align-items-center" key={playlist.id}>
                  <div className="track-info">
                    <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/playlist/${playlist.id}`}>
                      <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginRight: '12px' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
                    </a>
                    <img src={SpotifyImageModel.getSmallestImage(playlist.images)} alt="track" />
                    <span className="playlist-name">{playlist.name}</span>
                  </div>

                  {playlist.loading ? (
                    <IconSpinner />
                  ) : (
                    <div
                      className={`d-flex refresh-single-playlist align-items-center ${
                        appStatesService.backgroundFetch.state === BackgroundFetchStateEnum.IN_PROGRESS ? 'disabled' : ''
                      }`}>
                      <MdRefresh className="refresh-icon" onClick={() => refreshSource(playlist)} />
                      <div className="d-flex align-items-center track-actions" onClick={() => removeSource(playlist)}>
                        <div className="remove-playlist"></div>
                        <span className="button-text section-text-sm1">Remove</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="playlist-container d-flex flex-column spotify-sources">
            <div className="title article-title-lg1">Your playlist sources</div>
            {spotifyPlaylists.map(playlist => {
              return (
                <div className="tracks-container d-flex justify-content-between align-items-center" key={playlist.id}>
                  <div className="track-info">
                    <img src={SpotifyImageModel.getSmallestImage(playlist.images)} alt="track" />
                    <span className="playlist-name" title={playlist.name}>{playlist.name}</span>
                  </div>

                  {playlist.loading ? (
                    <IconSpinner />
                  ) : (
                    <div
                      className={`track-actions d-flex align-items-center ${
                        appStatesService.backgroundFetch.state === BackgroundFetchStateEnum.IN_PROGRESS ? 'disabled' : ''
                      }`}
                      onClick={() => addSource(playlist)}>
                      <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/playlist/${playlist.id}`}>
                        <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginLeft: 'auto', marginRight: '12px' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
                      </a>
                      <div className="add-playlist"></div>
                      <span className="button-text section-text-sm1">Add playlist</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
