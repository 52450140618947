import React from 'react';
import { PlayTrackButton } from '../../../../../../components';
import { AccountTypeEnum, PlayLocationEnum, SpotifyImageModel, TrackMetadataModel, TrackModel } from '../../../../../../data';
import './index.scss';

export type TrackSuggestionPropsType = {
  addSuggestionToPlaylist: Function;
  accountType: AccountTypeEnum;
  attributeX: keyof TrackMetadataModel;
  attributeY: keyof TrackMetadataModel;
  checkIfTrackIsAlreadyAdded: Function;
  currentlyHoveredSuggestion: string;
  currentlyPlayingTrack: TrackModel | undefined;
  currentlyPlayingLocation: PlayLocationEnum;
  changeCurrentlyHoveredSuggestion: Function;
  directHover: boolean;
  index: number;
  isPlaying: boolean;
  playTrack: Function;
  saveInProgress?: boolean;
  saveInProgressID?: string;
  track: TrackModel;
  togglePlayback: Function;
};

export const TrackSuggestion = (props: TrackSuggestionPropsType) => {
  return (
    <tr
      onMouseEnter={() => props.changeCurrentlyHoveredSuggestion(props.track.id)}
      onMouseLeave={() => props.changeCurrentlyHoveredSuggestion('')}
      className={`table-body ${props.currentlyHoveredSuggestion === props.track.id ? 'active' : 'passive'}`}
      style={{ verticalAlign:"middle" }}>    
      <td className="table-item-value track-unique section-text-sm1">
        <PlayTrackButton
          accountType={props.accountType}
          currentlyPlayingTrack={props.currentlyPlayingTrack}
          currentlyHoveredTrack={props.currentlyHoveredSuggestion}
          currentlyPlayingLocation={props.currentlyPlayingLocation}
          directHover={props.directHover}
          index={props.index}
          location={PlayLocationEnum.SUGGESTIONS}
          isPlaying={props.isPlaying}
          playTrack={props.playTrack}
          showZero={false}
          track={props.track}
          togglePlayback={props.togglePlayback}
        />
      </td>
      <td className="table-item-value track-item section-text-sm1 d-flex align-items-center">
        <img src={SpotifyImageModel.getSmallestImage(props.track.album.images)} alt="Album" />
        <span className="closest-track-info">
          <div title={props.track.artists[0].name}>{props.track.artists[0].name}</div>
          <div title={props.track.name}>{props.track.name}</div>
          <div title={props.track.album.name}>{props.track.album.name}</div>
        </span>
      </td>
      <td className="table-item-value section-text-sm1">{props.track.metadata.energy.toFixed(2)}</td>
      <td className="table-item-value section-text-sm1">{props.track.metadata.valence.toFixed(2)}</td>
      <td className="table-item-value section-text-sm1">{props.track.metadata.liveness.toFixed(2)}</td>
      <td className="table-item-value section-text-sm1">{props.track.metadata[props.attributeX].toFixed(2)}</td>
      <td className="table-item-value section-text-sm1">{props.track.metadata[props.attributeY].toFixed(2)}</td>
      <td className="table-item-value section-text-sm1">
        <a target="_blank" rel="noreferrer" href={`http://open.spotify.com/track/${props.track.id}`}>
          <img alt="Spotify Logo" style={{ width: '21px', height: '21px', marginLeft: 'auto', marginRight: 'auto' }} src="/spotify-icons-logos/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" />
        </a>
      </td>
      <td
        className={`controls-container d-flex align-items-center ${
          props.checkIfTrackIsAlreadyAdded(props.track) || (props.saveInProgress && props.saveInProgressID === props.track.id) ? 'disabled' : ''
        }`}
        onClick={() => props.addSuggestionToPlaylist(props.track)}>
        <div className="add-track"></div>
        <span className="button-text section-text-sm1">Add</span>
      </td>
    </tr>
  );
};
