import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Fragment, useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { Header, Spinner, SpotifyPlayer } from './components';
import { Home, Login, Logout, PlaylistBuilder, Page404, PlaylistSources } from './pages';
import {
  AppStatesServiceContext,
  AuthServiceContext,
  PlaylistServiceContext,
  PrivateRoute,
  PlayerServiceContext,
  SpotifyProviderServiceContext,
  ADAuthContext,
} from './services';
import { AppStatesEnum, BackgroundFetchStateEnum, BackgroundPlaylistFetchModel, TokenModel } from './data';
import { config } from './environments/config';
import './App.scss';
import { AuthOverlay } from './components/AuthOverlay';
import { AuthLoginAAD } from './pages/.auth/login/aad';

const ADAuthRoute = () => {
  return <Route path="/.auth">
    {/* <h2>.auth</h2> */}
    <ADAuthActionSwitch />
  </Route>;
};
const ADAuthActionSwitch = () => {
  let match = useRouteMatch();
  return <Switch>
      <Route path={`${match.path}/login`}>
        {/* <h3>Login</h3> */}
        <ADAuthProviderSwitch />
      </Route>
      <Route exact path={`${match.path}/logout`}>
        {/* <h3>Logout</h3> */}
      </Route>
    </Switch>;
};
const ADAuthProviderSwitch = () => {
  let match = useRouteMatch();
  return <Switch>
      <Route path={`${match.path}/aad`}>
        {/* <h4>AAD</h4> */}
        <AuthLoginAAD />
      </Route>
      {/* <Route path={`${match.path}/github`}>
        <h4>GitHub</h4>
      </Route>
      <Route path={`${match.path}/twitter`}>
        <h4>Twitter</h4>
      </Route> */}
    </Switch>;
};

export function App() {
  /* Set up contexts, throw error if any of them are not accessible */
  const appStatesService = useContext(AppStatesServiceContext);
  const authService = useContext(AuthServiceContext);
  const playerService = useContext(PlayerServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);
  const adAuth = useContext(ADAuthContext);

  if (!authService || !appStatesService || !playlistService || !playerService || !spotifyProviderService || !adAuth) throw new Error('Missing context!');

  /** Init function
  => Check if token exists and if it is valid
  => If user is authenticated load playlists from local storage
  => If playlists are found in local storage, fetch latest playlists data in background
  */
  useEffect(() => {
    if (appStatesService.appState === AppStatesEnum.LOADING) {
      appStatesService.updateAppState(AppStatesEnum.LOADED);
      const token = TokenModel.loadTokenFromLocalStorage();
      authService.whoAmI(token);
    } else if (appStatesService.appState === AppStatesEnum.USER_CHECKED) {
      if (authService.authenticated) {
        playlistService.loadPlaylistsFromLocalStorage();
        appStatesService.updatePlaylistsLoaded(true);
      }
      appStatesService.updateAppState(AppStatesEnum.READY);
    }
  }, [appStatesService, authService.authenticated, authService, playlistService]);

  /* Listen to Background fetch boolean and update data in background if necessary */
  useEffect(() => {
    if (appStatesService.backgroundFetch.state === BackgroundFetchStateEnum.INITIATED) {
      appStatesService.updateBackgroundFetch(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.IN_PROGRESS));
      playlistService.fetchAndUpdateDataInBackground(appStatesService.backgroundFetch.ids);
    }
  });

  const hiddenADRef = useRef<HTMLHeadingElement>(null);
  const handleKeyPress = useCallback((e) => {
    if(!hiddenADRef.current) return;
    if (e.key === '{' && e.shiftKey) {
      hiddenADRef.current.style.display = 'block';
    } else if (e.key === '}' && e.shiftKey) {
      hiddenADRef.current.style.display = 'none';
    }
  },[hiddenADRef]);
  useLayoutEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => document.removeEventListener('keypress', handleKeyPress);
  });

  return (
    <Fragment>
      {/** For build
       *    <BrowserRouter basename={document.querySelector('router-base')?.getAttribute('href') || '/'}>
       */}
      <BrowserRouter>
        <AuthOverlay />
        <div className="main-app-container">
          {appStatesService.appState === AppStatesEnum.READY ? (
            <Fragment>
              <Header />
              <h2 ref={hiddenADRef} style={{ display: 'none', paddingLeft: '300px', color:'white'}}>
                AD State:-{' '}
                Authenticated: {adAuth.authenticated?.toString()}
                , User: {adAuth.user ? JSON.stringify(adAuth.user) : '<N/A>'}
                <button onClick={() => adAuth.authorize()}>Login</button>
                <button onClick={() => adAuth.logout()}>Logout</button>
              </h2>
              <Switch>
                <Route
                  exact
                  path={[
                    config.routes.home,
                    config.routes.about,
                    config.routes.contact,
                    config.routes.legal,
                    config.routes.privacy,
                    config.routes.termsAndConditions,
                  ]}
                  component={Home}></Route>
                <Route
                  exact
                  path={config.routes.playlistSources}
                  render={routeProps => (
                    <Home {...routeProps}>
                      <PrivateRoute authenticated={authService.authenticated} component={PlaylistSources} {...routeProps}></PrivateRoute>
                    </Home>
                  )}></Route>
                <Route
                  exact
                  path={config.routes.playlistBuilderFull}
                  render={routeProps => (
                    <Home {...routeProps}>
                      <PrivateRoute authenticated={authService.authenticated} component={PlaylistBuilder} {...routeProps}></PrivateRoute>
                    </Home>
                  )}></Route>
                <Route path={config.routes.login} component={Login}></Route>
                <Route path={config.routes.logout} component={Logout}></Route>
                <ADAuthRoute />
                <Route path={config.routes.wildCard} component={Page404} />
              </Switch>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </div>
      </BrowserRouter>
      <SpotifyPlayer
        authService={authService}
        playerService={playerService}
        playlistService={playlistService}
        spotifyProviderService={spotifyProviderService}
      />
    </Fragment>
  );
}
