export const AADConfig = {
  // //clientID: '432a3a25dd3d4df490ac899b48afdaff',
  // clientID: '836a0b4717e44809bc36595949643d3a',
  // authorizeURL: 'https://accounts.spotify.com',
  authorizeURL: '/.auth/login/aad',
  // apiURL: 'https://api.spotify.com',
  // // redirectUri: 'http://localhost:3000/callback',
  redirectUri: `${(window ? window.location.origin : 'http://localhost:3000')}/callback`,
  //logoutUri: 'https://www.spotify.com/logout',
  logoutUri: '/.auth/logout',
  // playerName: 'Isofi web-app player',
  // playlistDescriptionIdentifier: 'Isofi playlist',
  // spotifyRequestLimit: 100,
  // numberOfSuggestions: 5,
  // userPlaylistsUri: 'https://api.spotify.com/v1/me/playlists',
  // whoAmIUri: 'https://api.spotify.com/v1/me',
  whoAmURI: '/.auth/me',
  // scopes: [
  //   'playlist-modify-private',
  //   'playlist-read-private',
  //   'playlist-modify-public',
  //   'playlist-read-collaborative',
  //   'user-read-playback-state',
  //   'user-modify-playback-state',
  //   'user-read-currently-playing',
  //   'user-library-modify',
  //   'user-library-read',
  //   'streaming',
  //   'user-read-email',
  //   'user-read-private',
  // ],
};
