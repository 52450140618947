// import React from 'react';
import './index.scss';

export const Login = () => {
  return (
    <div className="login-screen d-flex align-items-center justify-content-center">
      <span className="article-text-lg4">You successfully logged in!</span>
    </div>
  );
};
