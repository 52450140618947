export const SpotifyConfig = {
  //clientID: '432a3a25dd3d4df490ac899b48afdaff', // Lyrics API instances
  //clientID: '836a0b4717e44809bc36595949643d3a', // Glaivier's instance
  clientID: '1e9f0f0c50b74b87b8208d25e452b561', // ISOFI app instance
  authorizeURL: 'https://accounts.spotify.com',
  apiURL: 'https://api.spotify.com',
  // redirectUri: 'http://localhost:3000/callback',
  redirectUri: `${(window ? window.location.origin : 'http://localhost:3000')}/callback`,
  logoutUri: 'https://www.spotify.com/logout',
  playerName: 'Isofi web-app player',
  playlistDescriptionIdentifier: 'Isofi playlist',
  spotifyRequestLimit: 100,
  numberOfSuggestions: 5,
  userPlaylistsUri: 'https://api.spotify.com/v1/me/playlists',
  whoAmIUri: 'https://api.spotify.com/v1/me',
  scopes: [
    'playlist-modify-private',
    'playlist-read-private',
    'playlist-modify-public',
    'playlist-read-collaborative',
    'user-read-playback-state',
    'user-modify-playback-state',
    'user-read-currently-playing',
//    'user-library-modify',
    'user-library-read',
    'streaming',
//    'user-read-email',
    'user-read-private',
  ],
};

// export const SpotifyConfig = {
//   clientID: '3415efe3c5e6447cb53ff68d72b5aded',
//   authorizeURL: 'https://accounts.spotify.com',
//   apiURL: 'https://api.spotify.com',
//   redirectUri: 'https://isofi.private.intellegens.io/callback',
//   logoutUri: 'https://www.spotify.com/logout',
//   playerName: 'Isofi web-app player',
//   playlistDescriptionIdentifier: 'Isofi playlist',
//   spotifyRequestLimit: 100,
//   numberOfSuggestions: 5,
//   userPlaylistsUri: 'https://api.spotify.com/v1/me/playlists',
//   whoAmIUri: 'https://api.spotify.com/v1/me',
//   scopes: [
//     'playlist-modify-private',
//     'playlist-read-private',
//     'playlist-modify-public',
//     'playlist-read-collaborative',
//     'user-read-playback-state',
//     'user-modify-playback-state',
//     'user-read-currently-playing',
//     'user-library-modify',
//     'user-library-read',
//     'streaming',
//     'user-read-email',
//     'user-read-private',
//   ],
// };
